var Page;
(function (Page) {
    Page["Landing"] = "LandingPage";
    Page["About"] = "AboutPage";
    Page["BuyGiftCard"] = "BuyGiftCard";
    Page["BuyGiftCardBusiness"] = "BuyGiftCardBusiness";
    Page["GiftCardOrderConfirmation"] = "GiftCardOrderConfirmationPage";
    Page["Media"] = "MediaPage";
    Page["PartnerTcs"] = "PartnerTcsPage";
    Page["Partner"] = "PartnerPage";
    Page["Contact"] = "ContactPage";
    Page["Jobs"] = "JobsPage";
    Page["NomadyPactMain"] = "NomadyPactMainPage";
    Page["ConditionsOfParticipationPage"] = "ConditionsOfParticipationPage";
    Page["Camper"] = "CamperPage";
    Page["Checkout"] = "CheckoutPage";
    Page["Vision"] = "VisionPage";
    Page["Host"] = "HostPage";
    Page["Scout"] = "ScoutPage";
    Page["Search"] = "SearchPage";
    Page["ListingBase"] = "ListingBasePage";
    Page["Listing"] = "ListingPage";
    Page["ListingVariant"] = "ListingPageVariant";
    Page["NewListing"] = "NewListingPage";
    Page["EditListing"] = "EditListingPage";
    Page["EditListingPayout"] = "EditListingPayoutPage";
    Page["ListingCanonical"] = "ListingPageCanonical";
    Page["ProfileBase"] = "ProfileBasePage";
    Page["Profile"] = "ProfilePage";
    Page["ProfileSettings"] = "ProfileSettingsPage";
    Page["Region"] = "RegionPage";
    Page["Inspiration"] = "InspirationPage";
    Page["ListingFavoritesLists"] = "ListingFavoritesListsPage";
    Page["ListingFavoritesList"] = "ListingFavoritesListPage";
    Page["Login"] = "LoginPage";
    Page["Signup"] = "SignupPage";
    Page["PasswordRecovery"] = "PasswordRecoveryPage";
    Page["InboxBase"] = "InboxBasePage";
    Page["InboxCalendar"] = "InboxCalendarPage";
    Page["InboxOrders"] = "InboxOrdersPage";
    Page["AccountReports"] = "AccountReportsPage";
    Page["Inbox"] = "InboxPage";
    Page["LegalNotice"] = "LegalNoticePage";
    Page["Order"] = "OrderPage";
    Page["OrderDetails"] = "OrderDetailsPage";
    Page["Sale"] = "SalePage";
    Page["SaleDetails"] = "SaleDetailsPage";
    Page["ManageListings"] = "ManageListingsPage";
    Page["AccountSettings"] = "AccountSettingsPage";
    Page["ContactDetails"] = "ContactDetailsPage";
    Page["PasswordChange"] = "PasswordChangePage";
    Page["LanguageSettings"] = "LanguageSettingsPage";
    Page["StripePayoutOnboarding"] = "StripePayoutOnboardingPage";
    Page["TermsOfService"] = "TermsOfServicePage";
    Page["GiftCardTerms"] = "GiftCardTermsPage";
    Page["DiscountCodeTerms"] = "DiscountCodeTermsPage";
    Page["PrivacyPolicy"] = "PrivacyPolicyPage";
    Page["NomadyPact"] = "NomadyPactPage";
    Page["Fees"] = "FeesPage";
    Page["NotFound"] = "NotFoundPage";
    Page["PasswordReset"] = "PasswordResetPage";
    Page["EmailVerification"] = "EmailVerificationPage";
    Page["RentOfficeSpace"] = "RentOfficeSpacePage";
    Page["MyCabin"] = "MyCabinPage";
    Page["MyCabinCamper"] = "MyCabinCamperPage";
    Page["MyCabinHosts"] = "MyCabinHostsPage";
    Page["MyCabinGuide"] = "MyCabinGuidePage";
    Page["Fusion"] = "FusionPage";
    Page["HostFusion"] = "HostFusionPage";
    Page["PlateRedirect"] = "PlateRedirectPage";
    Page["Regions"] = "RegionsPage";
    Page["Inspirations"] = "InspirationsPage";
})(Page || (Page = {}));
export default Page;
