export const GIFT_CARD_CODE_LENGTH = 16;
export const GIFT_CARD_CODE_CHARACTER_POOL = 'ABCDEFGHJKLMNPQRSTUVWXYZ23456789';
export const MARKETPLACE_CURRENCY = 'CHF';
export var GIFT_CARD_SALE_TYPE;
(function (GIFT_CARD_SALE_TYPE) {
    GIFT_CARD_SALE_TYPE["DIGITAL_GIFT_CARD"] = "digital-gift-card";
    GIFT_CARD_SALE_TYPE["PHYSICAL_GIFT_CARD"] = "physical-gift-card";
})(GIFT_CARD_SALE_TYPE || (GIFT_CARD_SALE_TYPE = {}));
export const AVAILABLE_GIFT_CARD_VALUES = [
    25, 50, 75, 100, 125, 150, 200, 250, 300, 350, 400, 450, 500, 550, 600, 650,
    700, 750, 800, 850, 900, 950, 1000,
];
