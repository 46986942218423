import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useState, createRef, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components/macro';
import { types as sdkTypes } from '@nomady/shared/utils/sdkLoader';
import { pathByRouteName } from '@nomady/shared/utils/routes';
import Page from '@nomady/shared/routeConfiguration/Page';
import routeConfiguration from '@nomady/shared/routeConfiguration/routeConfiguration';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import Text, { TextVariant } from '../../styled-components/Text/Text';
import SearchMap from '../../styled-components/SearchMap/SearchMap';
import ListingCardOld, { ListingCardVariant, } from '../../styled-components/ListingCardOld/ListingCardOld';
import Icon, { IconSizeVariant, IconVariant, } from '../../styled-components/Icon/Icon';
import Button, { ButtonColorVariant, ButtonOutlineVariant, } from '../../styled-components/Button/Button';
import { fetchListingFavoritesList, listingFavoritesListError, listingFavoritesListSelector, listingFavoritesListsError, removeListingFavoritesList, updateListingFavoritesList, } from '../../slices/listingFavoritesListsSlice';
import { getBoundsFromListings } from '../../util/maps';
import IconButtonOld, { OldIconButtonColorVariant, OldIconButtonSizeVariant, } from '../../styled-components/IconButtonOld/IconButtonOld';
import EditFavoritesListBottomSheet from '../../styled-components/EditFavoritesListBottomSheet/EditFavoritesListBottomSheet';
import RemoveFavouritesListBottomSheet from '../../styled-components/RemoveFavoritesListBottomSheet/RemoveFavoritesListBottomSheet';
import SkeletonLoader, { SkeletonLoaderVariant, } from '../../styled-components/SkeletonLoader/SkeletonLoader';
import ErrorCard from '../../styled-components/ErrorCard/ErrorCard';
import MapPageContainer from '../../styled-components/PageContainer/MapPageContainer';
import { ButtonWrapper, CenteringWrapper, DesktopSkeletonLoaderWrapper, ErrorCardWrapper, Results, ResultCount, MapContainer, MobileSkeletonLoaderWrapper, ListingResults, } from '../../containers/SearchPage/SearchPage';
import { safeSpaceBottomSelector, safeSpaceTopSelector, } from '../../slices/UISlice';
const { UUID } = sdkTypes;
const Wrapper = styled.div `
  display: flex;
  flex-direction: row;
  height: 100%;
  align-items: stretch;
`;
const Title = styled(Text) `
  margin-bottom: 10px;
`;
const EditRemoveWrapper = styled.div `
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  flex: 1;
`;
const TitleContainer = styled.div `
  margin-inline: 15px;
`;
const ListingFavoritesListPage = props => {
    const safeSpaceTop = useSelector(safeSpaceTopSelector);
    const safeSpaceBottom = useSelector(safeSpaceBottomSelector);
    const [fullSizeMap, setFullSizeMap] = useState(false);
    const [activeListing, setActiveListing] = useState();
    const [isOpenRemove, setIsOpenRemove] = useState(false);
    const [isOpenEdit, setIsOpenEdit] = useState(false);
    const dispatch = useDispatch();
    const history = useHistory();
    const { id } = props.params;
    const listingFavoritesList = useSelector(listingFavoritesListSelector(id));
    const loadingInProgress = useSelector((state) => state.listingFavoritesLists.fetchListingFavoritesListInProgress);
    const listings = useSelector((state) => getMarketplaceEntities(state, (listingFavoritesList === null || listingFavoritesList === void 0 ? void 0 : listingFavoritesList.listings.map(id => ({
        id: new UUID(id),
        type: 'listing',
    }))) || [])) || [];
    const listingFavoritesListsErrorMessage = useSelector(listingFavoritesListsError);
    const listingFavoritesListErrorMessage = useSelector(listingFavoritesListError);
    const intl = useIntl();
    useEffect(() => {
        if (listingFavoritesList) {
            dispatch(fetchListingFavoritesList(id));
        }
    }, [listingFavoritesList]);
    const refs = listings.reduce((acc, value) => {
        acc[value.id.uuid] = createRef();
        return acc;
    }, {});
    const bounds = getBoundsFromListings(listings);
    const handleCloseRemoveBottomSheet = () => {
        setIsOpenRemove(false);
    };
    const handleRemoveFavouritesList = (id) => {
        dispatch(removeListingFavoritesList(id));
        setIsOpenRemove(false);
        const path = pathByRouteName(Page.ListingFavoritesLists, routeConfiguration);
        history.push(path);
    };
    const handleCloseEditBottomSheet = () => {
        setIsOpenEdit(false);
    };
    const handleUpdateFavouritesList = (name, id) => {
        dispatch(updateListingFavoritesList(id, name));
        setIsOpenEdit(false);
    };
    const description = '';
    const title = '';
    const schema = '';
    const onMapPinClicked = (listingId) => {
        var _a;
        setActiveListing(listingId);
        if (refs && listingId && ((_a = refs[listingId]) === null || _a === void 0 ? void 0 : _a.current)) {
            refs[listingId].current.scrollIntoView({
                block: 'start',
                behavior: 'smooth',
            });
        }
    };
    const handleOnRetry = () => {
        dispatch(fetchListingFavoritesList(id));
    };
    return (_jsx(MapPageContainer, Object.assign({ hideTopBar: fullSizeMap, description: description, title: title, schema: schema, noIndex: true }, { children: listingFavoritesListsErrorMessage || listingFavoritesListErrorMessage ? (_jsx(ErrorCardWrapper, { children: _jsx(ErrorCard, { title: intl.formatMessage({
                    id: 'SearchBarSheet.ErrorCard.title',
                }), description: intl.formatMessage({
                    id: 'SearchBarSheet.ErrorCard.description',
                }), onRetry: handleOnRetry, retryButtonLabel: intl.formatMessage({
                    id: 'SearchBarSheet.ErrorCard.retryButtonLabel',
                }) }) })) : (_jsxs(_Fragment, { children: [_jsxs(Wrapper, { children: [!fullSizeMap && (_jsxs(Results, { children: [_jsxs(TitleContainer, { children: [_jsx(Title, Object.assign({ variant: TextVariant.H6, as: "h2" }, { children: (listingFavoritesList === null || listingFavoritesList === void 0 ? void 0 : listingFavoritesList.name) ||
                                                intl.formatMessage({
                                                    id: 'ListingFavoritesListPage.titleNotFound',
                                                }) })), _jsxs(ResultCount, { children: [_jsx(Icon, { variant: IconVariant.Postcard }), intl.formatMessage({
                                                    id: 'ListingFavoritesListPage.numberOfListingsInList',
                                                }, {
                                                    count: listings.length,
                                                }), _jsxs(EditRemoveWrapper, { children: [_jsx(IconButtonOld, { onClick: () => setIsOpenRemove(true), iconVariant: IconVariant.Garbage, colorVariant: OldIconButtonColorVariant.Transparent, iconButtonSizeVariant: OldIconButtonSizeVariant.Medium, iconSizeVariant: IconSizeVariant.Small }), _jsx(IconButtonOld, { onClick: () => setIsOpenEdit(true), iconVariant: IconVariant.Pen, colorVariant: OldIconButtonColorVariant.Transparent, iconButtonSizeVariant: OldIconButtonSizeVariant.Medium, iconSizeVariant: IconSizeVariant.Small })] })] })] }), _jsxs(ListingResults, { children: [!loadingInProgress &&
                                            (listings === null || listings === void 0 ? void 0 : listings.map(listing => {
                                                var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o;
                                                return (_jsx("div", Object.assign({ ref: refs[listing.id.uuid] }, { children: _jsx(ListingCardOld, { variant: ListingCardVariant.Flag, id: listing.id, title: (_a = listing.attributes) === null || _a === void 0 ? void 0 : _a.title, campStyles: (_c = (_b = listing.attributes) === null || _b === void 0 ? void 0 : _b.publicData) === null || _c === void 0 ? void 0 : _c.campStyle, rating: (_e = (_d = listing === null || listing === void 0 ? void 0 : listing.attributes) === null || _d === void 0 ? void 0 : _d.metadata) === null || _e === void 0 ? void 0 : _e.rating, providerName: ((_h = (_g = (_f = listing === null || listing === void 0 ? void 0 : listing.author) === null || _f === void 0 ? void 0 : _f.attributes) === null || _g === void 0 ? void 0 : _g.profile) === null || _h === void 0 ? void 0 : _h.displayName) ||
                                                            '', verified: !!((_k = (_j = listing.attributes) === null || _j === void 0 ? void 0 : _j.metadata) === null || _k === void 0 ? void 0 : _k.isVerified), instantBooking: (_m = (_l = listing.attributes) === null || _l === void 0 ? void 0 : _l.publicData) === null || _m === void 0 ? void 0 : _m.instantBookingAvailable, price: (_o = listing.attributes) === null || _o === void 0 ? void 0 : _o.price, images: listing === null || listing === void 0 ? void 0 : listing.images.map((image) => { var _a, _b; return (_b = (_a = image.attributes) === null || _a === void 0 ? void 0 : _a.variants['landscape-crop']) === null || _b === void 0 ? void 0 : _b.url; }), active: listing.id.uuid === activeListing, responsive: true }) }), listing.id.uuid));
                                            })), loadingInProgress && (_jsxs(_Fragment, { children: [_jsx(MobileSkeletonLoaderWrapper, { children: _jsx(SkeletonLoader, { variant: SkeletonLoaderVariant.ListingCard }) }), _jsx(MobileSkeletonLoaderWrapper, { children: _jsx(SkeletonLoader, { variant: SkeletonLoaderVariant.ListingCard }) }), _jsx(MobileSkeletonLoaderWrapper, { children: _jsx(SkeletonLoader, { variant: SkeletonLoaderVariant.ListingCard }) }), _jsx(MobileSkeletonLoaderWrapper, { children: _jsx(SkeletonLoader, { variant: SkeletonLoaderVariant.ListingCard }) }), _jsx(MobileSkeletonLoaderWrapper, { children: _jsx(SkeletonLoader, { variant: SkeletonLoaderVariant.ListingCard }) }), _jsx(DesktopSkeletonLoaderWrapper, { children: _jsx(SkeletonLoader, { variant: SkeletonLoaderVariant.ListingCardFlag }) }), _jsx(DesktopSkeletonLoaderWrapper, { children: _jsx(SkeletonLoader, { variant: SkeletonLoaderVariant.ListingCardFlag }) }), _jsx(DesktopSkeletonLoaderWrapper, { children: _jsx(SkeletonLoader, { variant: SkeletonLoaderVariant.ListingCardFlag }) }), _jsx(DesktopSkeletonLoaderWrapper, { children: _jsx(SkeletonLoader, { variant: SkeletonLoaderVariant.ListingCardFlag }) }), _jsx(DesktopSkeletonLoaderWrapper, { children: _jsx(SkeletonLoader, { variant: SkeletonLoaderVariant.ListingCardFlag }) })] }))] }), _jsx(CenteringWrapper, { children: _jsx(ButtonWrapper, Object.assign({ safeSpaceBottom: safeSpaceBottom }, { children: _jsx(Button, { label: intl.formatMessage({
                                                id: 'ListingFavoritesListPage.mapViewButton',
                                            }), colorVariant: ButtonColorVariant.Dark, icon: IconVariant.Map, outlineVariant: ButtonOutlineVariant.OutlinedWhite, onClick: () => setFullSizeMap(true) }) })) })] })), !loadingInProgress && listings && (_jsxs(MapContainer, Object.assign({ fullSize: fullSizeMap, safeSpaceTop: safeSpaceTop }, { children: [_jsx(CenteringWrapper, { children: _jsx(ButtonWrapper, Object.assign({ safeSpaceBottom: safeSpaceBottom }, { children: _jsx(Button, { label: intl.formatMessage({
                                                id: 'ListingFavoritesListPage.listViewButton',
                                            }), colorVariant: ButtonColorVariant.Dark, icon: IconVariant.List, outlineVariant: ButtonOutlineVariant.OutlinedWhite, onClick: () => setFullSizeMap(false) }) })) }), _jsx(SearchMap, { bounds: bounds, listings: listings, activeListingId: activeListing, onMapPinClicked: listingId => onMapPinClicked(listingId), changeFullscreen: () => setFullSizeMap(!fullSizeMap), isFullscreen: fullSizeMap, onResetActiveListing: onMapPinClicked })] })))] }), _jsx(RemoveFavouritesListBottomSheet, { isOpen: isOpenRemove, onClose: handleCloseRemoveBottomSheet, onDelete: handleRemoveFavouritesList, listingFavoritesList: listingFavoritesList, listings: listings.length }), _jsx(EditFavoritesListBottomSheet, { isOpen: isOpenEdit, onClose: handleCloseEditBottomSheet, onUpdate: handleUpdateFavouritesList, listingFavoritesList: listingFavoritesList, listings: listings.length })] })) })));
};
export default ListingFavoritesListPage;
