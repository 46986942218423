import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import styled from 'styled-components/macro';
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import Page from '@nomady/shared/routeConfiguration/Page';
import GiftCardImageSrc from '../../assets/Giftcard_Banner1.png';
import Text, { TextVariant } from '../Text/Text';
import Button, { ButtonColorVariant, ButtonSizeVariant, } from '../Button/Button';
import { LayoutRow, LayoutRowBottomVariant, LayoutRowColorVariant, } from '../Layout/Layout';
export const Wrapper = styled.section `
  display: flex;
  flex-direction: column;
  padding: 38px 15px;

  @media ${props => props.theme.device.desktop} {
    padding: 20px 0;
    display: grid;
    grid-auto-columns: 1fr;
    grid-auto-flow: column;
    height: 180px;
  }
`;
const GiftCardImage = styled.img `
  width: 100%;
  @media ${props => props.theme.device.desktop} {
    width: 100%;
  }
`;
const ImageWrapper = styled.div `
  padding: 5px 0;
  display: flex;
  align-items: center;
  margin: 0 auto;
`;
const ButtonWrapper = styled.div `
  padding: 5px 0;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  @media ${props => props.theme.device.desktop} {
    justify-content: right;
  }
`;
export const Title = styled(Text) `
  text-align: center;

  @media ${props => props.theme.device.desktop} {
    display: flex;
    align-items: center;
    text-align: left;
  }
`;
const SectionGiftCard = ({ text, buttonText, }) => {
    return (_jsx(LazyLoadComponent, { children: _jsx(LayoutRow, Object.assign({ bottomVariant: LayoutRowBottomVariant.NoMargin, colorVariant: LayoutRowColorVariant.Pearl }, { children: _jsxs(Wrapper, { children: [_jsx(Title, Object.assign({ variant: TextVariant.H30 }, { children: text })), _jsx(ImageWrapper, { children: _jsx(GiftCardImage, { src: GiftCardImageSrc, alt: 'Giftcard Banner Image' }) }), _jsx(ButtonWrapper, { children: _jsx(Button, Object.assign({ targetRoute: Page.BuyGiftCard, colorVariant: ButtonColorVariant.Dark, sizeVariant: ButtonSizeVariant.Small, label: buttonText }, { children: buttonText })) })] }) })) }));
};
export default SectionGiftCard;
