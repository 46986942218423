import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import DynamicGoogleMap from './DynamicGoogleMap';
import StaticGoogleMap from './StaticGoogleMap';
import { EnsureUsercentricsConsent } from '../../hooks/UsercentricsProvider';
import { mapsConfig } from '../../util/maps';
export const Map = ({ center, useStaticMap, dimensions, zoom = mapsConfig.defaultZoomLevel, }) => {
    const mapLibLoaded = useStaticMap ? (_jsx(StaticGoogleMap, { center: center, zoom: zoom, dimensions: dimensions })) : (_jsx(DynamicGoogleMap, { center: center, zoom: zoom }));
    return (_jsx(EnsureUsercentricsConsent, Object.assign({ services: ['Google Maps'] }, { children: mapLibLoaded })));
};
export default Map;
