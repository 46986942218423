import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import styled from 'styled-components/macro';
import { useDropzone } from 'react-dropzone';
import { useIntl } from 'react-intl';
import Text, { TextVariant } from '../Text/Text';
import Icon, { IconVariant } from '../Icon/Icon';
import Button, { ButtonColorVariant, ButtonSizeVariant, } from '../Button/Button';
const Wrapper = styled.div `
  display: grid;
  row-gap: 4px;
  align-items: center;
  justify-items: center;
  flex-direction: column;
  border-radius: 16px;
  border: 1.5px dashed ${props => props.theme.color.fog};
  background: ${props => props.theme.color.pearl};

  padding: 8px;
  text-align: center;

  > button {
    width: min-content;
  }
`;
const InputPicturesUploadItem = ({ onChange }) => {
    const { getRootProps, getInputProps } = useDropzone({
        onDrop: onChange,
        accept: {
            'image/jpeg': ['.jpg', '.jpeg'],
            'image/png': ['.png'],
            'image/gif': ['.gif'],
        },
    });
    const intl = useIntl();
    return (
    // @ts-ignore react-dropzone types collide with @types/react
    _jsxs(Wrapper, Object.assign({}, getRootProps(), { children: [_jsx("input", Object.assign({}, getInputProps())), _jsx(Icon, { variant: IconVariant.Upload }), _jsx(Text, Object.assign({ variant: TextVariant.B30 }, { children: intl.formatMessage({ id: 'InputPicturesUploadItem.title' }) })), _jsx(Text, Object.assign({ variant: TextVariant.H12B }, { children: intl.formatMessage({
                    id: 'InputPicturesUploadItem.supportedFileInfo',
                }) })), _jsx(Button
            // Explicitly removed the event handler for clicks in favor of the underlying
            // useDropzone hook. Otherwise we experience problems on IOS.
            , { 
                // Explicitly removed the event handler for clicks in favor of the underlying
                // useDropzone hook. Otherwise we experience problems on IOS.
                label: intl.formatMessage({
                    id: 'InputPicturesUploadItem.selectFilesLabel',
                }), type: "button", colorVariant: ButtonColorVariant.Forest, sizeVariant: ButtonSizeVariant.Small })] })));
};
export default InputPicturesUploadItem;
