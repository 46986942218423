import { useEffect } from 'react';
import useBreakpoints from '../util/useMediaBreakpoints';
import { useDispatch, useSelector } from 'react-redux';
import { SnackBarType, addSnackBar, clearSnackBars, } from '../components/SnackBar/SnackBarSlice';
import { isApp } from '../util/deviceContext';
import { localeSelector } from '../slices/UISlice';
export const useContactJannis = () => {
    const dispatch = useDispatch();
    const { isTablet } = useBreakpoints();
    const userLanguage = useSelector(localeSelector).substring(0, 2);
    useEffect(() => {
        if (isApp) {
            return;
        }
        if (!isTablet) {
            return;
        }
        if (userLanguage !== 'de') {
            return;
        }
        const displayProbability = 0.5;
        const random = Math.random();
        if (random > displayProbability) {
            return;
        }
        const currentHourInGermany = new Date(new Date().toLocaleString('en-US', { timeZone: 'Europe/Berlin' })).getHours();
        if (currentHourInGermany < 9 || currentHourInGermany > 18) {
            return;
        }
        const timeoutId = setTimeout(() => {
            dispatch(clearSnackBars());
            dispatch(addSnackBar({
                type: SnackBarType.CONTACT_JANNIS,
                plainTitle: 'Hi, Jannis hier 👋\nWenn du Fragen hast, ruf mich gerne an 🙂',
                plainText: `D/EU: +49 7531 9218530
        CH: +41 55 5602014`,
                timeout: 1000 * 60 * 2,
            }));
        }, 1000 * 60 * 1.5);
        return () => clearTimeout(timeoutId);
    }, [dispatch, isTablet, userLanguage]);
};
