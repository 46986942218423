export var SectionType;
(function (SectionType) {
    SectionType["FaqSection"] = "page-section.faq";
    SectionType["ImageSliderSection"] = "page-section.images";
    SectionType["NewsletterSection"] = "page-section.newsletter";
    SectionType["ImageFlagSection"] = "page-section.image-flag";
    SectionType["TeamSection"] = "page-section.team";
    SectionType["PublicationSection"] = "page-section.publications";
    SectionType["CtaSection"] = "page-section.cta";
    SectionType["BenefitSection"] = "page-section.benefits";
    SectionType["ImageGridSection"] = "page-section.image-grid";
    SectionType["InspirationsSection"] = "page-section.inspirations";
    SectionType["VideoSection"] = "page-section.video";
    SectionType["TextSection"] = "page-section.text";
    SectionType["ImageSection"] = "page-section.single-image";
    SectionType["TermsOfServiceSection"] = "page-section.terms-of-service";
    SectionType["ListingSliderSection"] = "page-section.listing-slider";
    SectionType["ListingGridSection"] = "page-section.listing-grid";
    SectionType["ReviewSliderSection"] = "page-section.review-slider";
    SectionType["RegionsSection"] = "page-section.regions";
    SectionType["SearchPageLinksSection"] = "page-section.search-page-links";
    SectionType["LandingHeroSection"] = "page-section.landing-hero";
    SectionType["HeroSection"] = "page-section.hero";
    SectionType["LeadSection"] = "page-section.lead";
    SectionType["JobsSection"] = "page-section.jobs";
    SectionType["ImageFlagGridSection"] = "page-section.image-flag-grid";
    SectionType["BlogArticlesSection"] = "page-section.blog-articles";
    SectionType["GiftCardCtaSection"] = "page-section.gift-card-cta";
    SectionType["GiftCardShopSection"] = "page-section.gift-card-shop";
    SectionType["GiftCardOrderConfirmation"] = "page-section.gift-card-order-confirmation";
    SectionType["DataPrivacySection"] = "page-section.data-privacy-section";
    SectionType["MigrateMyCabinUserSection"] = "page-section.migrate-my-cabin-user";
})(SectionType || (SectionType = {}));
