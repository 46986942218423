import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import polyline from '@mapbox/polyline';
import { encodeLatLng, stringify } from '@nomady/shared/utils/urlHelpers';
import { withDimensions } from '../../util/contextHelpers';
import { circlePolyline, mapsConfig } from '../../util/maps';
import config from '../../config';
const DEFAULT_COLOR = 'FF0000';
const DEFAULT_STROKE_OPACITY = 0.3;
const DEFAULT_FILL_OPACITY = 0.2;
// Extract color from string. Given value should be either with '#' (e.g. #FFFFFF') or without it.
const formatColorFromString = (color) => {
    if (typeof color === 'string' && /^#[0-9A-F]{6}$/i.test(color)) {
        return color.substring(1).toUpperCase();
    }
    if (typeof color === 'string' && /^[0-9A-F]{6}$/i) {
        return color.toUpperCase();
    }
    return DEFAULT_COLOR;
};
// Convert opacity from floating point value (0.0 -> 1.0) to a hexadecimal format
const convertOpacity = (opacity) => {
    if (typeof opacity === 'number' &&
        !Number.isNaN(opacity) &&
        opacity >= 0 &&
        opacity <= 1) {
        // 0.2 => 20
        return Math.floor(opacity * 255)
            .toString(16)
            .toUpperCase();
    }
    return 0;
};
// Draw a circle polyline for fuzzy location.
const drawFuzzyCircle = (center) => {
    if (!(mapsConfig &&
        typeof mapsConfig === 'object' &&
        center &&
        typeof center === 'object')) {
        return '';
    }
    const fillColor = mapsConfig.circleColor;
    const fillOpacity = 0.2;
    const strokeColor = mapsConfig.circleColor;
    const strokeWeight = 1;
    const circleRadius = mapsConfig.maxOffset;
    const circleStrokeWeight = strokeWeight || 1;
    const circleStrokeColor = formatColorFromString(strokeColor);
    const circleStrokeOpacity = convertOpacity(DEFAULT_STROKE_OPACITY);
    const circleFill = formatColorFromString(fillColor);
    const circleFillOpacity = convertOpacity(fillOpacity || DEFAULT_FILL_OPACITY);
    // Encoded polyline string
    const encodedPolyline = polyline.encode(circlePolyline(center, circleRadius));
    const polylineGraphicTokens = [
        `color:0x${circleStrokeColor}${circleStrokeOpacity}`,
        `fillcolor:0x${circleFill}${circleFillOpacity}`,
        `weight:${circleStrokeWeight}`,
        `enc:${encodedPolyline}`,
    ];
    return polylineGraphicTokens.join('|');
};
const StaticGoogleMap = React.memo(({ center, zoom, dimensions }) => {
    const { width, height } = dimensions;
    const srcParams = stringify({
        center: encodeLatLng(center),
        zoom,
        size: `${width}x${height}`,
        scale: 2,
        maptype: 'satellite',
        key: config.maps.googleMapsAPIKey,
        path: drawFuzzyCircle(center),
    });
    return (_jsx("img", { src: `https://maps.googleapis.com/maps/api/staticmap?${srcParams}`, alt: "Google Maps" }));
});
export default withDimensions(StaticGoogleMap, { maxWidth: '100%' });
