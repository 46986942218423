import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { formatCurrencyMajorUnit } from '@nomady/shared/utils/currency';
import { createSlug } from '@nomady/shared/utils/urlHelpers';
import Page from '@nomady/shared/routeConfiguration/Page';
import { pathByRouteName } from '@nomady/shared/utils/routes';
import ImageCarousel, { ImageCarouselSizeVariant, } from '../ImageCarousel/ImageCarousel';
import Text, { TextVariant } from '../Text/Text';
import ReviewRating from '../Review/ReviewRating';
import { NamedLink } from '../../components';
import { ColorVariant } from '../../theme';
import Icon, { IconSizeVariant, IconVariant } from '../Icon/Icon';
import LikeButton from '../LikeButton/LikeButton';
import AddToFavoritesModal from '../AddToFavoritesModal';
import { routeConfiguration } from '../..';
import { isAuthenticatedSelector } from '../../ducks/Auth.duck';
import { listingIdsInListingFavoritesListsSelector } from '../../slices/listingFavoritesListsSlice';
import { ModalId, setLastOpenModal } from '../../slices/UISlice';
import campStyleIcons from '../../campStyleIcons';
const Wrapper = styled.div `
  width: 240px;
  height: 200px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  background-color: ${props => props.theme.color.pearl};
  overflow: hidden;
`;
const Head = styled.div `
  position: relative;
  height: 140px;
`;
const ListingImages = styled.div ``;
const FavoriteButton = styled.div `
  position: absolute;
  top: 10px;
  left: 10px;
`;
const RightIconsContainer = styled.div `
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 5px;
`;
const Content = styled.div `
  display: flex;
  flex-direction: column;
  height: 50px;
  width: 100%;
  padding: 6px 15px 6px 15px;
`;
const TopInformation = styled.div `
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
const BottomInformation = styled.div `
  margin-top: 3px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-top: 1px solid ${props => props.theme.color.snow};
`;
const Title = styled(Text) `
  margin: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
const CampStyles = styled.div `
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;
const CampStyle = styled.div `
  margin-right: 3px;
  padding: 0;
`;
const Price = styled(Text) ``;
const SearchMapListingCard = ({ price, id, images, rating, title, campStyles, instantBooking, verified, }) => {
    const [favoriteBottomSheetOpen, setFavoriteBottomSheetOpen] = useState(false);
    const history = useHistory();
    const isAuthenticated = useSelector(isAuthenticatedSelector);
    const liked = useSelector(listingIdsInListingFavoritesListsSelector(id.uuid));
    const intl = useIntl();
    const currentURL = useSelector((state) => state.Routing.currentCanonicalUrl);
    const dispatch = useDispatch();
    const openFavoritesBottomSheet = () => {
        if (isAuthenticated) {
            setFavoriteBottomSheetOpen(true);
        }
        else {
            const path = pathByRouteName(Page.Signup, routeConfiguration);
            const payload = {
                modalId: ModalId.AddToFavorites,
                metaData: Object.assign(Object.assign({}, id), { backUrl: currentURL || undefined }),
            };
            dispatch(setLastOpenModal(payload));
            history.push(path);
        }
    };
    const wrapperClicked = (event) => {
        event.stopPropagation();
    };
    return (_jsxs(Wrapper, Object.assign({ onClick: wrapperClicked, onDoubleClick: wrapperClicked }, { children: [_jsxs(Head, { children: [_jsx(ListingImages, { children: _jsx(ImageCarousel, { sizeVariant: ImageCarouselSizeVariant.SMALL, images: images, listingId: id.uuid, listingTitle: title }) }), _jsxs(FavoriteButton, { children: [_jsx(LikeButton, { liked: liked, onClick: openFavoritesBottomSheet }), _jsx(AddToFavoritesModal, { open: favoriteBottomSheetOpen, onClose: () => setFavoriteBottomSheetOpen(false), listingId: id })] }), _jsxs(RightIconsContainer, { children: [verified && (_jsx(Icon, { variant: IconVariant.BubbleCheckmark, sizeVariant: IconSizeVariant.Small })), instantBooking && (_jsx(Icon, { variant: IconVariant.BubbleFlash, sizeVariant: IconSizeVariant.Small }))] })] }), _jsx(Content, { children: _jsxs(NamedLink, Object.assign({ name: Page.Listing, params: {
                        id: id.uuid,
                        slug: createSlug(title),
                    } }, { children: [_jsxs(TopInformation, { children: [_jsx(Title, Object.assign({ variant: TextVariant.H13, colorVariant: ColorVariant.Cave }, { children: title })), _jsx(ReviewRating, { rating: rating })] }), _jsxs(BottomInformation, { children: [_jsx(CampStyles, { children: campStyles === null || campStyles === void 0 ? void 0 : campStyles.map(campStyle => (_jsx(Icon, { variant: campStyleIcons[campStyle], sizeVariant: IconSizeVariant.Small }, `icon-${campStyle}`))) }), _jsx(Price, Object.assign({ variant: TextVariant.H11B, colorVariant: ColorVariant.Cave }, { children: formatCurrencyMajorUnit(intl, price) }))] })] })) })] })));
};
export default SearchMapListingCard;
