/* eslint-disable react/display-name */
import React from 'react';
import Page from '@nomady/shared/routeConfiguration/Page';
import BaseRoutes from '@nomady/shared/routeConfiguration/BaseRoutes';
import routeConfiguration, { ACCOUNT_SETTINGS_TABS, LEGAL_TABS, } from '@nomady/shared/routeConfiguration/routeConfiguration';
import { CmsSlug } from '@nomady/shared/cmsConfig';
import { CreateAccountPage, ContactDetailsPage, CmsContentPage, EditListingPage, EmailVerificationPage, ListingPage, LanguageSettingsPage, ManageListingsPage, NotFoundPage, TouristTaxReportsPage, PasswordChangePage, PasswordRecoveryPage, PasswordResetPage, ProfilePage, ProfileSettingsPage, ListingFavoritesListsPage, ListingFavoritesListPage, SearchPage, TransactionPage, LoginPage, } from './containers';
// routeConfiguration needs to initialize containers first
// Otherwise, components will import form container eventually and
// at that point css bundling / imports will happen in wrong order.
import { NamedRedirect } from './components';
import { loadInspirationData, loadPageData, } from './containers/CmsContentPage/CmsContentPageSlice';
import { loadData as TouristTaxReportsPageLoadData } from './containers/TouristTaxReportsPage/TouristTaxReportsPageSlice';
import { loadData as BookingsPageLoadData } from './containers/BookingsPage/BookingsPageSlice';
import { loadData as JourneysPageLoadData } from './containers/JourneysPage/JourneysPageSlice';
import { loadData as ListingPageLoadData } from './containers/ListingPage/ListingPageSlice';
import { loadData as EditListingPageLoadData } from './containers/EditListingPage/EditListingPageSlice';
import { loadData as ProfilePageLoadData } from './containers/ProfilePage/ProfilePage.duck';
import { setInitialValues as CheckoutPageSetInitialValues } from './containers/CheckoutPage/CheckoutPageSlice';
import { loadData as TransactionPageLoadData, setInitialValues as TransactionPageSetInitialValues, } from './containers/TransactionPage/TransactionPage.duck';
import * as cmsDataSlice from './slices/cmsDataSlice';
import BookingsPage from './containers/BookingsPage/BookingsPage';
import JourneysPage from './containers/JourneysPage/JourneysPage';
import CheckoutPage from './containers/CheckoutPage/CheckoutPage';
import CalendarPage from './containers/CalendarPage/CalendarPage';
import PlateRedirectPage from './containers/PlateRedirectPage/PlateRedirectPage';
import { RegionWrapper } from './containers/CmsContentPage/RegionWrapper';
// https://en.wikipedia.org/wiki/Universally_unique_identifier#Nil_UUID
const draftId = '00000000-0000-0000-0000-000000000000';
const draftSlug = 'draft';
const RedirectToLandingPage = () => React.createElement(NamedRedirect, { name: Page.Landing });
// NOTE: Most server-side endpoints are prefixed with /api. Requests to those
// endpoints are indended to be handled in the server instead of the browser and
// they will not render the application. So remember to avoid routes starting
// with /api and if you encounter clashing routes see server/index.js if there's
// a conflicting route defined there.
const CmsContentPageConfig = [
    { id: BaseRoutes.Landing, slug: CmsSlug.Home },
    { id: BaseRoutes.AboutUs, slug: CmsSlug.AboutUs },
    { id: BaseRoutes.BuyGiftCard, slug: CmsSlug.BuyGiftCard },
    { id: BaseRoutes.BuyGiftCardBusiness, slug: CmsSlug.BuyGiftCardBusiness },
    {
        id: BaseRoutes.GiftCardOrderConfirmation,
        slug: CmsSlug.GiftCardOrderConfirmation,
    },
    { id: BaseRoutes.Media, slug: CmsSlug.Media },
    { id: BaseRoutes['Partner.Tcs'], slug: CmsSlug.PartnerTcs },
    { id: BaseRoutes.Partners, slug: CmsSlug.Partner },
    { id: BaseRoutes.Contact, slug: CmsSlug.Contact },
    { id: BaseRoutes.Jobs, slug: CmsSlug.Jobs },
    { id: BaseRoutes.NomadyPact, slug: CmsSlug.NomadyPact },
    { id: BaseRoutes.Camper, slug: CmsSlug.Camper },
    { id: BaseRoutes.Vision, slug: CmsSlug.Vision },
    { id: BaseRoutes.Host, slug: CmsSlug.Host },
    { id: BaseRoutes.Scout, slug: CmsSlug.Scout },
    {
        id: BaseRoutes.RentOfficeSpace,
        slug: CmsSlug.RentOfficeSpace,
    },
    {
        id: BaseRoutes.MyCabin,
        slug: CmsSlug.MyCabin,
    },
    {
        id: BaseRoutes.MyCabinCamper,
        slug: CmsSlug.MyCabinCamper,
    },
    {
        id: BaseRoutes.MyCabinHosts,
        slug: CmsSlug.MyCabinHosts,
    },
    {
        id: BaseRoutes.Fusion,
        slug: CmsSlug.Fusion,
    },
    {
        id: BaseRoutes.HostFusion,
        slug: CmsSlug.HostFusion,
    },
    {
        id: BaseRoutes['Legal.TermsOfService'],
        tabs: LEGAL_TABS,
        slug: CmsSlug.TermsAndConditions,
    },
    {
        id: BaseRoutes['Legal.GiftCardTerms'],
        tabs: LEGAL_TABS,
        slug: CmsSlug.GiftCardTerms,
    },
    {
        id: BaseRoutes['Legal.DiscountCodeTerms'],
        tabs: LEGAL_TABS,
        slug: CmsSlug.DiscountCodeTerms,
    },
    {
        id: BaseRoutes['Legal.PrivacyPolicy'],
        tabs: LEGAL_TABS,
        slug: CmsSlug.PrivacyPolicy,
    },
    {
        id: BaseRoutes['Legal.NomadyPact'],
        tabs: LEGAL_TABS,
        slug: CmsSlug.NomadyPact,
    },
    {
        id: BaseRoutes['Legal.Fees'],
        tabs: LEGAL_TABS,
        slug: CmsSlug.Fees,
    },
    {
        id: BaseRoutes['Legal.ConditionsOfParticipation'],
        tabs: LEGAL_TABS,
        slug: CmsSlug.ConditionsOfParticipation,
    },
    {
        id: BaseRoutes['Legal.LegalNotice'],
        tabs: LEGAL_TABS,
        slug: CmsSlug.LegalNotice,
    },
    {
        id: BaseRoutes.Regions,
        slug: CmsSlug.Regions,
    },
    {
        id: BaseRoutes.Inspirations,
        slug: CmsSlug.Inspirations,
    },
];
// Our routes are exact by default.
// See behaviour from Routes.js where Route is created.
const BaseRouteConfiguration = [
    {
        id: BaseRoutes.Checkout,
        component: CheckoutPage,
        setInitialValues: CheckoutPageSetInitialValues,
    },
    ...CmsContentPageConfig.map(({ id, slug, tabs }) => ({
        id,
        component: !tabs
            ? CmsContentPage
            : () => React.createElement(CmsContentPage, { tabs }),
        loadData: () => loadPageData(slug),
    })),
    {
        id: BaseRoutes.Search,
        component: SearchPage,
        loadData: SearchPage.loadData,
    },
    {
        id: BaseRoutes['Listing.Index'],
        component: RedirectToLandingPage,
    },
    {
        id: BaseRoutes['Listing.Single'],
        component: ListingPage,
        loadData: ListingPageLoadData,
    },
    {
        id: BaseRoutes['Listing.Variant'],
        authPage: Page.Login,
        component: ListingPage,
        loadData: ListingPageLoadData,
    },
    {
        id: BaseRoutes['Listing.Create'],
        component: () => React.createElement(NamedRedirect, {
            name: Page.EditListing,
            params: {
                slug: draftSlug,
                id: draftId,
                type: 'new',
                tab: 'description',
            },
        }),
    },
    {
        id: BaseRoutes['Listing.Edit.Index'],
        component: EditListingPage,
        loadData: EditListingPageLoadData,
    },
    {
        id: BaseRoutes['Listing.Edit.Payout'],
        component: EditListingPage,
        loadData: EditListingPageLoadData,
    },
    // Canonical path should be after the `/l/new` path since they
    // conflict and `new` is not a valid listing UUID.
    {
        id: BaseRoutes['Listing.Canonical'],
        component: ListingPage,
        loadData: ListingPageLoadData, // `??
    },
    {
        id: BaseRoutes['Profile.Index'],
        component: RedirectToLandingPage,
    },
    {
        id: BaseRoutes['Profile.Single'],
        component: ProfilePage,
        loadData: ProfilePageLoadData,
    },
    {
        id: BaseRoutes['Profile.Settings'],
        authPage: Page.Login,
        component: () => React.createElement(ProfileSettingsPage, { tabs: ACCOUNT_SETTINGS_TABS }),
    },
    {
        id: BaseRoutes['Region.Single'],
        component: RegionWrapper,
    },
    {
        id: BaseRoutes['Inspiration.Single'],
        component: CmsContentPage,
        loadData: loadInspirationData,
    },
    {
        id: BaseRoutes.ListingFavoritesLists,
        authPage: Page.Login,
        component: ListingFavoritesListsPage,
    },
    {
        id: BaseRoutes.ListingFavoritesList,
        authPage: Page.Login,
        component: ListingFavoritesListPage,
    },
    {
        id: BaseRoutes.Login,
        component: LoginPage,
    },
    {
        id: BaseRoutes.Signup,
        component: CreateAccountPage,
        loadData: cmsDataSlice.loadData,
    },
    {
        id: BaseRoutes.PasswordRecovery,
        component: PasswordRecoveryPage,
    },
    {
        id: BaseRoutes['Bookings.Sales'],
        authPage: Page.Login,
        component: BookingsPage,
        loadData: BookingsPageLoadData,
    },
    {
        id: BaseRoutes['Bookings.Calendar'],
        authPage: Page.Login,
        component: CalendarPage,
    },
    {
        id: BaseRoutes['Bookings.Orders'],
        authPage: Page.Login,
        component: JourneysPage,
        loadData: JourneysPageLoadData,
    },
    {
        id: BaseRoutes['Account.Reports'],
        authPage: Page.Login,
        component: () => React.createElement(TouristTaxReportsPage, {
            tabs: ACCOUNT_SETTINGS_TABS,
        }),
        loadData: TouristTaxReportsPageLoadData,
    },
    {
        id: BaseRoutes['Bookings.Index'],
        authPage: Page.Login,
        component: (props) => React.createElement(NamedRedirect, {
            name: Page.InboxOrders,
        }),
    },
    {
        id: BaseRoutes['Bookings.Order.Index'],
        authPage: Page.Login,
        component: (props) => React.createElement(NamedRedirect, {
            name: Page.OrderDetails,
            params: Object.assign({}, props.params),
        }),
    },
    {
        id: BaseRoutes['Bookings.Order.Details'],
        authPage: Page.Login,
        component: (props) => React.createElement(TransactionPage, Object.assign(Object.assign({}, props), { transactionRole: 'customer' })),
        loadData: (params) => TransactionPageLoadData(Object.assign(Object.assign({}, params), { transactionRole: 'customer' })),
        setInitialValues: TransactionPageSetInitialValues,
    },
    {
        id: BaseRoutes['Bookings.Sale.Index'],
        authPage: Page.Login,
        component: (props) => React.createElement(NamedRedirect, {
            name: Page.SaleDetails,
            params: Object.assign({}, props.params),
        }),
    },
    {
        id: BaseRoutes['Bookings.Sale.Details'],
        authPage: Page.Login,
        component: (props) => React.createElement(TransactionPage, Object.assign(Object.assign({}, props), { transactionRole: 'provider' })),
        loadData: (params) => TransactionPageLoadData(Object.assign(Object.assign({}, params), { transactionRole: 'provider' })),
    },
    {
        id: BaseRoutes.Listings,
        authPage: Page.Login,
        component: ManageListingsPage,
        loadData: ManageListingsPage.loadData,
    },
    {
        id: BaseRoutes['Account.Index'],
        authPage: Page.Login,
        component: () => React.createElement(NamedRedirect, { name: Page.ContactDetails }),
    },
    {
        id: BaseRoutes['Account.ContactDetails'],
        authPage: Page.Login,
        component: () => React.createElement(ContactDetailsPage, { tabs: ACCOUNT_SETTINGS_TABS }),
    },
    {
        id: BaseRoutes['Account.PasswordChange'],
        authPage: Page.Login,
        component: () => React.createElement(PasswordChangePage, {
            tabs: ACCOUNT_SETTINGS_TABS,
        }),
    },
    {
        id: BaseRoutes['Account.Language'],
        authPage: Page.Login,
        component: (props) => React.createElement(LanguageSettingsPage, {
            location: props.location,
            tabs: ACCOUNT_SETTINGS_TABS,
        }),
    },
    {
        id: BaseRoutes['Error.NotFound'],
        component: NotFoundPage,
    },
    // Do not change this path!
    //
    // The API expects that the application implements /reset-password endpoint
    {
        id: BaseRoutes.PasswordReset,
        component: PasswordResetPage,
    },
    // Do not change this path!
    //
    // The API expects that the application implements /verify-email endpoint
    {
        id: BaseRoutes.VerifyEmail,
        authPage: Page.Login,
        component: EmailVerificationPage,
    },
    {
        id: BaseRoutes.PlateRedirect,
        component: PlateRedirectPage,
    },
];
const routeConfigurationWithComponents = (() => {
    const routes = [];
    routeConfiguration.forEach(element => {
        routes.push(Object.assign(Object.assign({}, element), BaseRouteConfiguration.find(route => route.id === element.id)));
    });
    return routes;
})();
export default routeConfigurationWithComponents;
