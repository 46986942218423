import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import GoogleMapReact from 'google-map-react';
import config from '../../config';
import { mapsConfig } from '../../util/maps';
const DynamicGoogleMap = ({ center, zoom, }) => {
    const circleProps = {
        fillColor: mapsConfig.circleColor,
        fillOpacity: 0.2,
        strokeColor: mapsConfig.circleColor,
        strokeOpacity: 0.5,
        strokeWeight: 1,
        clickable: false,
        radius: mapsConfig.maxOffset,
        center: {
            lat: center.lat,
            lng: center.lng,
        },
    };
    return (_jsx(GoogleMapReact, { bootstrapURLKeys: { key: config.maps.googleMapsAPIKey }, zoom: zoom, center: { lat: center.lat, lng: center.lng }, resetBoundsOnResize: true, options: {
            styles: config.custom.mapStyles,
            mapTypeControl: false,
            scrollwheel: false,
            fullscreenControl: false,
            streetViewControl: false,
            zoomControlOptions: {
                position: 5,
            },
        }, yesIWantToUseGoogleMapApiInternals: true, onGoogleApiLoaded: ({ map }) => new google.maps.Circle(Object.assign({ map }, circleProps)) }));
};
export default DynamicGoogleMap;
