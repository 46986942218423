import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components/macro';
import { useElements } from '@stripe/react-stripe-js';
import { PaymentMode } from '@nomady/shared/config';
import { useDispatch, useSelector } from 'react-redux';
import SectionLayout, { DescriptionContainer } from './SectionLayout';
import StripeForm from '../StripeForm';
import GiftCardDiscountCodeForm from '../GiftCardDiscountCodeForm';
import { addGiftCard, paymentModeSelector, removeGiftCard, setCustomerCommissionDiscountCode, togglePaymentMode, } from '../../CheckoutPageSlice';
import InfoLabel from '../../../../styled-components/InfoLabel';
import Icon, { IconVariant } from '../../../../styled-components/Icon/Icon';
import Text, { TextVariant } from '../../../../styled-components/Text/Text';
import { ColorVariant } from '../../../../theme';
import { InfoCard } from '../../../CalendarPage/components/InfoCard';
import Button, { ButtonOutlineVariant, ButtonSizeVariant, } from '../../../../styled-components/Button/Button';
import { isApp } from '../../../../util/deviceContext';
const Descriptions = styled.div `
  margin-bottom: 31px;

  @media ${props => props.theme.device.tablet} {
    margin-bottom: 35px;
  }
`;
const ContentWrapper = styled.div `
  margin-top: 20px;
`;
const InfoCardWrapper = styled.div `
  margin: 20px 0;
`;
const paymentModeCtaTexts = {
    [PaymentMode.Custom]: {
        title: 'CheckoutSection.payWithGiftCard.Title',
        text: 'CheckoutSection.payWithGiftCard.Text',
        cta: 'CheckoutSection.payWithGiftCard.Cta',
    },
    [PaymentMode.Legacy]: {
        title: 'PaymentSection.payWithCustom.Title',
        text: 'PaymentSection.payWithCustom.Text',
        cta: 'PaymentSection.payWithCustom.Cta',
    },
};
const PaymentSection = ({ requiresStripePayment, stripePayinTotal, listing, bookingData, providerStripeAccountId, existingTransaction, lineItemCalculator, giftCards, customerCommissionDiscountCode, onPaymentSuccessful, currentUser, formRef, bookingStart, bookingEnd, onCheckoutErrorOccurred, onExecuteOrder, }) => {
    var _a, _b;
    const intl = useIntl();
    const dispatch = useDispatch();
    const elements = useElements();
    const paymentMode = useSelector(paymentModeSelector);
    const togglePaymentMethod = () => {
        elements === null || elements === void 0 ? void 0 : elements.update({
            // @ts-ignore This property is not typed https://stripe.com/docs/api/payment_intents/object#payment_intent_object-on_behalf_of
            onBehalfOf: null,
        });
        dispatch(togglePaymentMode());
    };
    return (_jsx(SectionLayout, Object.assign({ title: intl.formatMessage({
            id: 'StripePaymentForm.paymentHeading',
        }) }, { children: _jsxs(ContentWrapper, { children: [requiresStripePayment && (_jsxs(_Fragment, { children: [_jsxs(Descriptions, { children: [!listing.attributes.publicData.instantBookingAvailable && (_jsxs(_Fragment, { children: [_jsx(InfoLabel, Object.assign({ title: intl.formatMessage({
                                                id: 'StripePaymentForm.reservedPayment',
                                            }), dataTestId: "PaymentSection_reservedPayment" }, { children: _jsx(Icon, { variant: IconVariant.PaymentCard }) })), _jsx(DescriptionContainer, { children: _jsx(Text, Object.assign({ variant: TextVariant.B50, colorVariant: ColorVariant.Cave }, { children: intl.formatMessage({
                                                    id: 'StripePaymentForm.reserveExplanation',
                                                }) })) })] })), !isApp && (_jsx(InfoCardWrapper, { children: _jsxs(InfoCard, Object.assign({ title: intl.formatMessage({
                                            id: paymentModeCtaTexts[paymentMode].title,
                                        }) }, { children: [intl.formatMessage({
                                                id: paymentModeCtaTexts[paymentMode].text,
                                            }), _jsx(Button, { label: intl.formatMessage({
                                                    id: paymentModeCtaTexts[paymentMode].cta,
                                                }), sizeVariant: ButtonSizeVariant.Small, outlineVariant: ButtonOutlineVariant.Outlined, onClick: togglePaymentMethod })] })) }))] }), _jsx(StripeForm, { existingTransaction: existingTransaction, bookingData: bookingData, bookingStart: bookingStart, bookingEnd: bookingEnd, listing: listing, lineItemCalculator: lineItemCalculator, giftCards: giftCards, customerCommissionDiscountCode: customerCommissionDiscountCode, formRef: formRef, providerStripeAccountId: providerStripeAccountId, onPaymentSuccessful: onPaymentSuccessful, currentUser: currentUser, onCheckoutErrorOccurred: onCheckoutErrorOccurred, onExecuteOrder: onExecuteOrder })] })), ((_b = (_a = listing === null || listing === void 0 ? void 0 : listing.attributes) === null || _a === void 0 ? void 0 : _a.publicData) === null || _b === void 0 ? void 0 : _b.currency) && (_jsx(_Fragment, { children: _jsx(GiftCardDiscountCodeForm, { paymentMode: paymentMode, giftCardCodes: giftCards, customerCommissionDiscountCode: customerCommissionDiscountCode, addGiftCardCode: (code) => dispatch(addGiftCard(code)), addCustomerCommissionDiscountCode: (code) => dispatch(setCustomerCommissionDiscountCode(code)), removeGiftCardCode: (id) => dispatch(removeGiftCard(id)), removeCustomerCommissionDiscountCode: () => dispatch(setCustomerCommissionDiscountCode(undefined)), listingCurrency: listing.attributes.publicData.currency, stripePayinTotal: stripePayinTotal, bookingStart: bookingStart, bookingEnd: bookingEnd }) }))] }) })));
};
export default PaymentSection;
