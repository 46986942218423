var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { createRef, useState, useEffect, } from 'react';
import styled from 'styled-components/macro';
import { injectIntl } from 'react-intl';
import { fitBounds } from 'google-map-react';
import DefaultMap from '../DefaultMap/DefaultMap';
import IconButtonOld, { OldButtonBorderRadiusVariant, OldIconButtonSizeVariant, } from '../IconButtonOld/IconButtonOld';
import { IconVariant } from '../Icon/Icon';
import { obfuscatedCoordinates } from '../../util/maps';
import LoadingAnimation from '../../components/LoadingAnimation/LoadingAnimation';
import Button, { ButtonSizeVariant } from '../Button/Button';
import { useSelector } from 'react-redux';
import { maxResultsPerSearchResultPage } from '@nomady/shared/marketplace-custom-config';
const DEFAULT_ZOOM = 11;
const MapWrapper = styled.div `
  position: relative;
  width: 100%;
  height: 100vh;
`;
const MapControlsLeft = styled.div `
  position: absolute;
  top: 10px;
  left: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;
const MapControlsCenter = styled.div `
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translate(-50%, 0);

  @media ${props => props.theme.device.tablet} {
    transform: translate(-100%, 0);
  }
`;
const MapControlsCenterSecondRow = styled.div `
  position: absolute;
  top: 64px;
  left: 50%;
  transform: translate(-50%, -50%);
`;
const ZoomInfoButton = styled.div ``;
const MapControlsRight = styled.div `
  position: absolute;
  top: 10px;
  right: 10px;
  display: grid;
  grid-template-columns: auto;
  grid-auto-rows: auto;
  row-gap: 4px;
`;
const HideOnMobileButton = styled.div `
  display: none;

  @media ${props => props.theme.device.desktop} {
    display: grid;
    grid-template-columns: auto;
    grid-auto-rows: auto;
    row-gap: 2px;
  }
`;
const HideOnDesktop = styled.div `
  display: block;

  @media ${props => props.theme.device.desktop} {
    display: none;
  }
`;
const BackButton = styled.div `
  svg {
    stroke-width: 2px;
    transform: scale(0.8);
  }
`;
export const withCoordinatesObfuscated = (listings) => {
    return listings.map(listing => {
        const { id, attributes } = listing, rest = __rest(listing, ["id", "attributes"]);
        const origGeolocation = attributes.geolocation;
        const cacheKey = id
            ? `${id.uuid}_${origGeolocation.lat}_${origGeolocation.lng}`
            : undefined;
        const geolocation = obfuscatedCoordinates(origGeolocation, cacheKey);
        return Object.assign(Object.assign({ id }, rest), { attributes: Object.assign(Object.assign({}, attributes), { geolocation }) });
    });
};
const SearchMap = ({ bounds, listings, onBoundsChanged, onCenterLocation, intl, onMapPinClicked, onResetActiveListing, activeListingId, changeFullscreen, isFullscreen, showLocationSearch = false, onCenterLocationIsInProgress = false, searchInProgress = false, }) => {
    var _a;
    const containerRef = createRef();
    const [mapCenter, setMapCenter] = useState(null);
    const [mapZoom, setMapZoom] = useState(DEFAULT_ZOOM);
    const onZoomChanged = (newZoom) => {
        if (newZoom !== mapZoom) {
            setMapZoom(newZoom);
        }
    };
    useEffect(() => {
        var _a, _b, _c;
        const clientWidthIsZero = ((_a = containerRef === null || containerRef === void 0 ? void 0 : containerRef.current) === null || _a === void 0 ? void 0 : _a.clientWidth) === 0;
        if ((bounds === null || bounds === void 0 ? void 0 : bounds.ne) && (bounds === null || bounds === void 0 ? void 0 : bounds.sw) && !clientWidthIsZero) {
            const mapSize = {
                height: ((_b = containerRef === null || containerRef === void 0 ? void 0 : containerRef.current) === null || _b === void 0 ? void 0 : _b.clientHeight) || window.innerHeight,
                width: ((_c = containerRef === null || containerRef === void 0 ? void 0 : containerRef.current) === null || _c === void 0 ? void 0 : _c.clientWidth) || window.innerWidth,
            };
            const fittedBounds = fitBounds({
                ne: bounds.ne,
                sw: bounds.sw,
            }, mapSize);
            setMapCenter(fittedBounds.center);
            onZoomChanged(fittedBounds.zoom);
        }
    }, [bounds, isFullscreen, (_a = containerRef === null || containerRef === void 0 ? void 0 : containerRef.current) === null || _a === void 0 ? void 0 : _a.clientWidth]);
    const obfuscatedListings = withCoordinatesObfuscated(listings);
    const { pagination } = useSelector((state) => state.SearchPage);
    return (_jsxs(MapWrapper, Object.assign({ ref: containerRef, onClick: () => activeListingId && onResetActiveListing && onResetActiveListing() }, { children: [_jsx(DefaultMap, { listings: obfuscatedListings, bounds: bounds, onBoundsChanged: (newBounds) => {
                    if (onBoundsChanged) {
                        onBoundsChanged(newBounds);
                    }
                }, onMapPinClicked: onMapPinClicked, activeListingId: activeListingId, zoom: mapZoom, mapCenter: mapCenter, isFullscreen: isFullscreen }), _jsxs(MapControlsLeft, { children: [_jsx(HideOnDesktop, { children: _jsx(BackButton, { children: _jsx(IconButtonOld, { iconVariant: IconVariant.ArrowLeft, iconButtonSizeVariant: OldIconButtonSizeVariant.Medium, buttonBorderRadiusVariant: OldButtonBorderRadiusVariant.Default, onClick: (event) => {
                                    event.stopPropagation();
                                    changeFullscreen();
                                } }) }) }), _jsx(HideOnMobileButton, { children: isFullscreen ? (_jsx(IconButtonOld, { iconVariant: IconVariant.Minimize, iconButtonSizeVariant: OldIconButtonSizeVariant.Medium, buttonBorderRadiusVariant: OldButtonBorderRadiusVariant.Squared, onClick: (event) => {
                                event.stopPropagation();
                                changeFullscreen();
                            } })) : (_jsx(IconButtonOld, { iconVariant: IconVariant.Fullscreen, iconButtonSizeVariant: OldIconButtonSizeVariant.Medium, buttonBorderRadiusVariant: OldButtonBorderRadiusVariant.Squared, onClick: (event) => {
                                event.stopPropagation();
                                changeFullscreen();
                            } })) })] }), _jsx(MapControlsCenter, { children: searchInProgress ? (_jsx(LoadingAnimation, { isLoading: searchInProgress, height: 32 })) : (_jsx(HideOnDesktop, { children: _jsx(Button, { label: intl.formatMessage({
                            id: 'SearchPage.foundResults',
                        }, {
                            count: pagination === null || pagination === void 0 ? void 0 : pagination.totalItems,
                        }), sizeVariant: ButtonSizeVariant.Small }) })) }), (pagination === null || pagination === void 0 ? void 0 : pagination.totalItems) > maxResultsPerSearchResultPage && (_jsx(HideOnDesktop, { children: _jsx(MapControlsCenterSecondRow, { children: _jsx(ZoomInfoButton, { children: _jsx(Button, { label: intl.formatMessage({
                                id: 'SearchPage.zoomToSeeMoreOnTheMap',
                            }), sizeVariant: ButtonSizeVariant.Small }) }) }) })), _jsxs(MapControlsRight, { children: [_jsxs(HideOnMobileButton, { children: [_jsx(IconButtonOld, { iconVariant: IconVariant.Plus, iconButtonSizeVariant: OldIconButtonSizeVariant.Medium, buttonBorderRadiusVariant: OldButtonBorderRadiusVariant.Squared, onClick: (event) => {
                                    event.stopPropagation();
                                    onZoomChanged(mapZoom + 1);
                                } }), _jsx(IconButtonOld, { iconVariant: IconVariant.Minus, iconButtonSizeVariant: OldIconButtonSizeVariant.Medium, buttonBorderRadiusVariant: OldButtonBorderRadiusVariant.Squared, onClick: (event) => {
                                    event.stopPropagation();
                                    onZoomChanged(mapZoom - 1);
                                } })] }), showLocationSearch && (_jsx(IconButtonOld, { iconVariant: IconVariant.Compass, iconButtonSizeVariant: OldIconButtonSizeVariant.Medium, buttonBorderRadiusVariant: OldButtonBorderRadiusVariant.Squared, inProgress: onCenterLocationIsInProgress, onClick: (event) => {
                            event.stopPropagation();
                            if (onCenterLocation) {
                                onCenterLocation();
                            }
                        } }))] })] })));
};
export default injectIntl(SearchMap);
