import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components/macro';
import { ColorVariant } from '../../theme';
import Icon, { IconVariant } from '../Icon/Icon';
import Text, { TextVariant } from '../Text/Text';
import { getBackgroundImageUrlLarge, getBackgroundImageUrlMedium, } from '../../util/imageHelper';
export var InspirationArticleCategory;
(function (InspirationArticleCategory) {
    InspirationArticleCategory["Travel"] = "Travel";
})(InspirationArticleCategory || (InspirationArticleCategory = {}));
export var InspirationArticleSizeVariant;
(function (InspirationArticleSizeVariant) {
    InspirationArticleSizeVariant["Default"] = "Big";
    InspirationArticleSizeVariant["Small"] = "Small";
    InspirationArticleSizeVariant["Big"] = "Big";
})(InspirationArticleSizeVariant || (InspirationArticleSizeVariant = {}));
const Wrapper = styled.article `
  width: 280px;
  display: flex;
  flex-direction: column;
  margin-right: 30px;
  background-color: ${props => props.theme.color.pearl};
  border-radius: 10px;
  padding: 10px;
  height: 100%;

  @media ${props => props.theme.device.tablet} {
    width: 370px;
  }

  @media ${props => props.theme.device.tabletL} {
    ${props => props.sizeVariant === InspirationArticleSizeVariant.Big &&
    css `
        flex-direction: row;
        width: 650px;
      `}
  }
  @media ${props => props.theme.device.desktop} {
    ${props => props.sizeVariant === InspirationArticleSizeVariant.Big &&
    css `
        width: 710px;
      `}
  }
`;
const Link = styled.a `
  font-weight: initial;
  color: initial;
`;
const Image = styled.div `
  width: 100%;
  height: 205px;
  background-image: url(${props => { var _a, _b; return ((_b = (_a = props.formats) === null || _a === void 0 ? void 0 : _a.small) === null || _b === void 0 ? void 0 : _b.url) || props.background; }});

  background-position: center;
  background-size: cover;
  border-radius: 10px;
  flex-shrink: 0;

  @media ${props => props.theme.device.tablet} {
    background-image: url(${props => getBackgroundImageUrlMedium(props.formats, props.background)});
  }

  @media ${props => props.theme.device.tabletL} {
    ${props => props.sizeVariant === InspirationArticleSizeVariant.Big &&
    css `
        width: 310px;
        margin-right: 10px;
        height: 100%;
        min-height: 342px;
      `}
    background-image: url(${props => getBackgroundImageUrlMedium(props.formats, props.background)});
  }

  @media ${props => props.theme.device.desktop} {
    ${props => props.sizeVariant === InspirationArticleSizeVariant.Big &&
    css `
        width: 371px;
        height: 100%;
        min-height: 410px;
      `}
    background-image: url(${props => getBackgroundImageUrlLarge(props.formats, props.background)});
  }

  @media ${props => props.theme.device.desktopL} {
    background-image: url(${props => props.background});
  }
`;
const Content = styled.div `
  margin-top: 20px;
  padding-left: 10px;
  padding-right: 10px;
  ${props => props.sizeVariant === InspirationArticleSizeVariant.Small &&
    `margin-top: 10px;`}
`;
const Category = styled.div `
  ${props => props.theme.typeset.H10B}
  display:flex;
  align-items: center;
  margin-bottom: 10px;
`;
const CategoryIcon = styled.div `
  margin-right: 10px;
`;
const Title = styled(Text) `
  display: block;
  margin-bottom: 10px;
`;
const Excerpt = styled(Text) `
  display: block;
  margin-bottom: 5px;
  font-weight: normal;
`;
const InspirationArticle = ({ article: { image, title, excerpt, category, tags, targetLink }, sizeVariant, }) => {
    const intl = useIntl();
    return (_jsx(Link, Object.assign({ href: targetLink }, { children: _jsxs(Wrapper, Object.assign({ sizeVariant: sizeVariant }, { children: [_jsx(LazyLoadComponent, { children: _jsx(Image, { formats: image.data.attributes.formats, background: image.data.attributes.url, sizeVariant: sizeVariant }) }), _jsxs(Content, Object.assign({ sizeVariant: sizeVariant }, { children: [category === InspirationArticleCategory.Travel && (_jsxs(Category, { children: [_jsx(CategoryIcon, { children: _jsx(Icon, { variant: IconVariant.Backpack, colorVariant: ColorVariant.Fog }) }), intl.formatMessage({ id: 'InspirationArticle.categoryTravel' })] })), _jsx(Title, Object.assign({ variant: TextVariant.H6 }, { children: title })), _jsx(Excerpt, Object.assign({ variant: TextVariant.H10B }, { children: excerpt }))] }))] })) })));
};
export default InspirationArticle;
