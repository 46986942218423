import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useMemo } from 'react';
import styled, { css } from 'styled-components/macro';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import debounce from 'lodash/debounce';
import Page from '@nomady/shared/routeConfiguration/Page';
import { createResourceLocatorString } from '@nomady/shared/utils/routes';
import Icon, { IconSizeVariant, IconVariant } from '../Icon/Icon';
import Text, { TextVariant } from '../Text/Text';
import { getShowResetAll, loadResults, resetSearchQuery, setActiveSearchBarSheet, queryLocationSearchResults, getShowReset, resetCurrentSearchBarSheet, } from '../../slices/listingSearchSlice';
import SearchBarSheetLocation from './Location/Location';
import SearchBarSheetDates from './Dates';
import SearchBarSheetGuests from './Guests';
import SearchBarSheetFeatures from './Features';
import { ColorVariant } from '../../theme';
import { routeConfiguration } from '../..';
import getQueryStringFromState from '../../util/search-query/getQueryStringFromState';
import SearchBarSheetVariant from './SearchBarSheetVariant';
import IconButton, { IconButtonColorVariant, IconButtonSizeVariant, } from '../IconButton/IconButton';
import { useFilterSummary } from '../ListingSearchBar/utils';
import Button2024, { ButtonSizeVariant, ButtonVariant, } from '../Button2024/Button2024';
import { safeSpaceTopSelector } from '../../slices/UISlice';
const Wrapper = styled.div `
  display: grid;
  grid-template-columns: 100vw;
  grid-template-rows: [topEnd] min-content min-content [contentStart] 1fr [contentEnd] min-content [bottomEnd];

  background-color: ${props => props.theme.color.snow};
  height: 100%;

  @media ${props => props.theme.device.tablet} {
    max-height: 560px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    height: auto;
    border-radius: 16px;

    grid-template-columns: initial;
    grid-template-rows: initial;

    border: 1px solid ${props => props.theme.color.stone};
  }
`;
const MobileTop = styled.div `
  padding: 14px 16px 10px 16px;
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;

  @media ${props => props.theme.device.tablet} {
    display: none;
  }
`;
const Content = styled.div `
  flex-grow: 1;
  grid-column: 1;
  grid-row-start: contentStart;
  grid-row-end: contentEnd;

  padding: 16px;
  overflow-y: auto;
  width: 100%;
  background: ${props => props.theme.color.basicSnowGrey};
  position: relative;

  box-shadow: inset 0px 1px 0px 0px rgba(0, 0, 0, 0.25);

  @media ${props => props.theme.device.tablet} {
    border-radius: 16px;

    ::-webkit-scrollbar-thumb {
      border-radius: 16px;
    }
  }
`;
const Bottom = styled.div `
  grid-column: 1;
  grid-row-start: contentEnd;
  grid-row-end: bottomEnd;
  position: sticky;
  bottom: 0px;
  padding-bottom: ${props => props.safeSpaceTop}px;
  user-select: none;

  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  background: ${props => props.theme.color.pearl};
  box-shadow: 0 0 10px 5px rgb(142 142 142 / 30%);

  @media ${props => props.theme.device.tablet} {
    background: ${props => props.theme.color.snow};
    box-shadow: unset;
    position: sticky;
    border-radius: 0;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }
`;
const Navigation = styled.nav `
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 16px;

  @media ${props => props.theme.device.tablet} {
    padding-top: 8px;
    display: none;
  }
`;
const NavigationButton = styled.div `
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 4px;
  padding: 14px;
  border-radius: 16px;
  flex: 0 1 auto;
  min-width: 0;
  transition: padding 0.4s ease, box-shadow 0.4s ease, flex 0.4s ease;

  ${props => props.active &&
    css `
      /* padding: 14px 20px 14px 14px; */
      box-shadow: 0px 0px 5px -1px ${props.theme.color.functionElectricVioletActive},
        inset 0 0 0 1px ${props.theme.color.functionElectricVioletActive};
      flex: 1 0 auto;
    `}
`;
const NavigationButtonText = styled(Text) `
  overflow: hidden;
  white-space: nowrap;
  transition: max-width 0.4s ease;

  ${props => props.active
    ? css `
          max-width: 100px;
        `
    : css `
          max-width: 0;
        `}
`;
const MobileActions = styled.div `
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 8px 16px 16px 8px;

  @media ${props => props.theme.device.tablet} {
    display: none;
  }
`;
const ResetAction = styled.div ``;
const SearchAction = styled.div ``;
const TextsWrapper = styled.div `
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  overflow: hidden;
  min-width: 0;
`;
const SingleLineText = styled(Text) `
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
`;
const SearchBarSheetIcons = {
    Location: IconVariant.LocationPin,
    Dates: IconVariant.Calendar_NEW,
    Guests: IconVariant.Avatar,
    Features: IconVariant.Filter,
};
const SearchBarSheet = ({ onClose, }) => {
    var _a;
    const history = useHistory();
    const safeSpaceTop = useSelector(safeSpaceTopSelector);
    const intl = useIntl();
    const { currentSearchQuery, activeSearchBarSheet, searchResultsCount } = useSelector((state) => state.listingSearch);
    const { searchInProgress } = useSelector((state) => state.SearchPage);
    const queryString = useSelector(getQueryStringFromState);
    const showResetAll = useSelector(getShowResetAll);
    const showReset = useSelector(getShowReset);
    const dispatch = useDispatch();
    const onChangeLocation = (search) => {
        dispatch(queryLocationSearchResults(search));
    };
    const debouncedOnChangeLocation = useMemo(() => debounce(onChangeLocation, 750), []);
    useEffect(() => {
        return () => {
            debouncedOnChangeLocation.cancel();
        };
    }, []);
    const resetAllSheets = () => {
        dispatch(resetSearchQuery());
        dispatch(loadResults());
        dispatch(setActiveSearchBarSheet(SearchBarSheetVariant.Location));
    };
    const resetCurrentSheet = () => {
        dispatch(resetCurrentSearchBarSheet());
        dispatch(loadResults());
    };
    const showResults = () => {
        onClose();
        const target = createResourceLocatorString(Page.Search, routeConfiguration, {}, queryString);
        return history.push(target);
    };
    const { title, subtitle } = useFilterSummary();
    return (_jsxs(Wrapper, { children: [_jsxs(MobileTop, { children: [_jsx(IconButton, { iconVariant: IconVariant.X_Close, iconButtonSizeVariant: IconButtonSizeVariant.Small, colorVariant: IconButtonColorVariant.Light, onClick: onClose }), _jsxs(TextsWrapper, { children: [_jsx(SingleLineText, Object.assign({ variant: TextVariant.L1SB, colorVariant: ColorVariant.BasicCaveBlack }, { children: title ||
                                    (activeSearchBarSheet &&
                                        intl.formatMessage({
                                            id: `SearchBarSheet.${activeSearchBarSheet}.title`,
                                        })) })), subtitle && (_jsx(SingleLineText, Object.assign({ variant: TextVariant.L4SB, colorVariant: ColorVariant.FunctionStoneGreyInactive }, { children: subtitle })))] })] }), _jsxs(Content, { children: [activeSearchBarSheet === SearchBarSheetVariant.Location && (_jsx(SearchBarSheetLocation, { searchString: ((_a = currentSearchQuery.location) === null || _a === void 0 ? void 0 : _a.searchString) || '' })), activeSearchBarSheet === SearchBarSheetVariant.Dates && (_jsx(SearchBarSheetDates, {})), activeSearchBarSheet === SearchBarSheetVariant.Guests && (_jsx(SearchBarSheetGuests, {})), activeSearchBarSheet === SearchBarSheetVariant.Features && (_jsx(SearchBarSheetFeatures, {}))] }), _jsxs(Bottom, Object.assign({ safeSpaceTop: safeSpaceTop }, { children: [_jsx(Navigation, { children: Object.keys(SearchBarSheetVariant).map(element => (_jsxs(NavigationButton, Object.assign({ onClick: () => dispatch(setActiveSearchBarSheet(element)), active: activeSearchBarSheet === element }, { children: [_jsx(Icon, { variant: SearchBarSheetIcons[element], sizeVariant: IconSizeVariant.Medium, colorVariant: ColorVariant.BasicCaveBlack }), _jsx(NavigationButtonText, Object.assign({ variant: TextVariant.L1SB, colorVariant: ColorVariant.BasicCaveBlack, active: activeSearchBarSheet === element }, { children: element &&
                                        intl.formatMessage({
                                            id: `SearchBarSheet.${element}.navigationButtonTitle`,
                                        }) }))] }), element))) }), _jsxs(MobileActions, { children: [_jsxs(ResetAction, { children: [showReset && (_jsx(Button2024, { sizeVariant: ButtonSizeVariant.Small, variant: ButtonVariant.Link, onClick: () => resetCurrentSheet(), label: intl.formatMessage({
                                            id: 'SearchBarSheet.resetCurrentSearchBarSheet',
                                        }) })), showResetAll && !showReset && (_jsx(Button2024, { sizeVariant: ButtonSizeVariant.Small, variant: ButtonVariant.Link, onClick: () => resetAllSheets(), label: intl.formatMessage({
                                            id: 'SearchBarSheet.resetSearchQueryButton',
                                        }) }))] }), _jsxs(SearchAction, { children: [searchResultsCount !== 0 && (_jsx(Button2024, { label: intl.formatMessage({
                                            id: 'SearchBarSheet.showResultsButton',
                                        }, {
                                            count: searchInProgress ? '' : searchResultsCount,
                                        }), sizeVariant: ButtonSizeVariant.Small, variant: ButtonVariant.Primary, onClick: showResults, iconOnLeft: searchInProgress ? IconVariant.Spinner : undefined })), searchResultsCount === 0 && (_jsx(Button2024, { label: intl.formatMessage({
                                            id: 'SearchBarSheet.noResults',
                                        }), sizeVariant: ButtonSizeVariant.Small, variant: ButtonVariant.Primary, destructive: true, onClick: showResults, iconOnLeft: searchInProgress ? IconVariant.Spinner : undefined }))] })] })] }))] }));
};
export default SearchBarSheet;
