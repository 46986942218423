import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { useIntl } from 'react-intl';
import { IconSizeVariant, } from '../../../styled-components/Icon/Icon';
import Panel from '../../../styled-components/Panel/Panel';
import InfoLabel from '../../../styled-components/InfoLabel';
import Modal from '../../../styled-components/Modal/Modal';
import { ColorVariant } from '../../../theme';
const ContentWrap = styled.div `
  display: flex;
  flex-direction: column;
`;
const ListingModal = ({ dataTestId, iconVariant, titleId, title, subtitle, id, toggleModalVariant, children, titleIdAdditionalData, disabled = false, iconSizeVariant = IconSizeVariant.Default, iconColorVariant = ColorVariant.Tar, }) => {
    const [isOpen, setIsOpen] = useState(false);
    const intl = useIntl();
    const buttonTitle = titleId
        ? intl.formatMessage({ id: titleId }, Object.assign({}, titleIdAdditionalData))
        : title;
    const childrenWithOnClose = React.cloneElement(children, {
        onClose: () => setIsOpen(false),
    });
    return (_jsxs(_Fragment, { children: [_jsx(Panel, Object.assign({ dataTestId: dataTestId, onClick: () => !disabled && setIsOpen(true), iconVariant: iconVariant, iconSizeVariant: iconSizeVariant, iconColorVariant: iconColorVariant }, { children: _jsx(InfoLabel, { title: buttonTitle || '', subtitle: subtitle, variant: toggleModalVariant }) })), _jsx(Modal, Object.assign({ id: `ListingModal-${id}`, onClose: () => setIsOpen(false), isOpen: !!isOpen, title: buttonTitle }, { children: _jsx(ContentWrap, { children: childrenWithOnClose }) }))] }));
};
export default ListingModal;
