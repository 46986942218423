import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components/macro';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import Text, { TextVariant } from '../../Text/Text';
import config from '../../../config';
import { IconVariant } from '../../Icon/Icon';
import SearchBarSheetVariant from '../SearchBarSheetVariant';
import { cleanGeolocation, isGeolocationUsedSelector, loadResults, queryLocationDetails, queryLocationSearchResults, resetLocation, setActiveSearchBarSheet, setLocation, setLocationFlexible, setLocationSearchInProgress, setSort, } from '../../../slices/listingSearchSlice';
import { setShowRequestBrowserGeolocationModal, setGeolocationSource, } from '../../../slices/geolocationSlice';
import GeolocationSource from '../../../util/GeolocationSource';
import { localeSelector } from '../../../slices/UISlice';
import AnimatedDots from '../../LoadingDots/LoadingDots';
import { setPage } from '../../../containers/SearchPage/SearchPageSlice';
import Button2024, { ButtonSizeVariant, ButtonVariant, } from '../../Button2024/Button2024';
import Input from '../../ListingSearchBar/Input';
import { DEFAULT_SORT_KEY, distanceSortKey, } from '@nomady/shared/marketplace-custom-config';
const Wrapper = styled.div `
  display: flex;
  flex-direction: column;
  row-gap: 32px;
`;
const Section = styled.div `
  display: flex;
  flex-direction: column;
  row-gap: 8px;
`;
const Title = styled(Text) ``;
const Content = styled.div `
  margin-left: -16px;
  margin-top: -8px;
`;
const CountryButtonWrapper = styled.div `
  margin-left: -16px;
  display: flex;
  flex-wrap: wrap;
`;
const CountryButtonContentWrapper = styled.div `
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: -6px;
  margin-bottom: -6px;
`;
const MobileSearchInputWrapper = styled.div `
  display: block;
  position: relative;

  @media ${props => props.theme.device.tablet} {
    display: none;
  }
`;
const ClearIconWrapper = styled.div `
  position: absolute;
  right: 16px;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
`;
const shuffledRegions = config.maps.search.defaults;
const SearchBarSheetLocation = ({ searchString }) => {
    var _a, _b, _c, _d, _e, _f;
    const intl = useIntl();
    const locale = useSelector(localeSelector);
    const dispatch = useDispatch();
    const onSetShowRequestBrowserGeolocationModal = (isOpen) => dispatch(setShowRequestBrowserGeolocationModal(isOpen));
    const onSetFlexibleLocation = () => {
        dispatch(setLocationFlexible(true));
        dispatch(setPage(1));
        dispatch(loadResults());
    };
    const onSetLocation = (location) => {
        dispatch(cleanGeolocation());
        dispatch(setLocation(location));
        dispatch(setPage(1));
        dispatch(loadResults());
    };
    const onClickSearchResult = (placeId) => {
        dispatch(cleanGeolocation());
        dispatch(queryLocationDetails(placeId, locale));
    };
    const openNextSearchBarSheet = () => dispatch(setActiveSearchBarSheet(SearchBarSheetVariant.Dates));
    const onSetGeolocationSource = (source) => dispatch(setGeolocationSource(source));
    const { locationResults } = useSelector((state) => state.listingSearch.currentSearchQuery.location);
    const { locationSearchInProgress } = useSelector((state) => state.listingSearch);
    const hasLocationSearchResults = locationResults &&
        0 < (locationResults === null || locationResults === void 0 ? void 0 : locationResults.length) &&
        (searchString === null || searchString === void 0 ? void 0 : searchString.length) !== 0;
    const hasSearchInput = (searchString === null || searchString === void 0 ? void 0 : searchString.length) > 0;
    const hasNoLocationSearchResults = (locationResults === null || locationResults === void 0 ? void 0 : locationResults.length) === 0 && 0 < (searchString === null || searchString === void 0 ? void 0 : searchString.length) && hasSearchInput;
    const [showAnimatedDots, setShowAnimatedDots] = useState(false);
    useEffect(() => {
        let timer;
        if (locationSearchInProgress) {
            timer = setTimeout(() => {
                setShowAnimatedDots(true);
            }, 1000);
        }
        else {
            setShowAnimatedDots(false);
        }
        return () => {
            clearTimeout(timer);
            setShowAnimatedDots(false);
        };
    }, [locationSearchInProgress]);
    const mobileLocationInputRef = useRef(null);
    const onChangeLocation = (search) => {
        dispatch(queryLocationSearchResults(search));
    };
    const { currentSearchQuery } = useSelector((state) => state.listingSearch);
    const onResetLocation = () => {
        dispatch(resetLocation());
        if (currentSearchQuery.sort === distanceSortKey) {
            dispatch(setSort(DEFAULT_SORT_KEY));
        }
        dispatch(loadResults());
        setTimeout(() => {
            var _a;
            (_a = mobileLocationInputRef.current) === null || _a === void 0 ? void 0 : _a.focus();
        }, 0);
    };
    const geolocationUsed = useSelector(isGeolocationUsedSelector);
    const isLocation = ((_b = (_a = currentSearchQuery.location) === null || _a === void 0 ? void 0 : _a.location) === null || _b === void 0 ? void 0 : _b.name) ||
        ((_c = currentSearchQuery.location) === null || _c === void 0 ? void 0 : _c.isFlexible) ||
        geolocationUsed;
    const flexibleAddress = !((_e = (_d = currentSearchQuery.location) === null || _d === void 0 ? void 0 : _d.location) === null || _e === void 0 ? void 0 : _e.name) &&
        ((_f = currentSearchQuery.location) === null || _f === void 0 ? void 0 : _f.isFlexible);
    const getPlaceholder = () => {
        var _a, _b;
        if (flexibleAddress) {
            return intl.formatMessage({
                id: 'SearchBarSheet.Location.flexible',
            });
        }
        else if (geolocationUsed) {
            return intl.formatMessage({
                id: 'SearchBarSheet.Location.geolocation',
            });
        }
        else if (!flexibleAddress && !geolocationUsed) {
            return (_b = (_a = currentSearchQuery.location) === null || _a === void 0 ? void 0 : _a.location) === null || _b === void 0 ? void 0 : _b.name;
        }
        else {
            return intl.formatMessage({
                id: 'SearchBarSheet.Location.searchPlaceholder',
            });
        }
    };
    const placeholder = getPlaceholder();
    return (_jsxs(Wrapper, { children: [_jsxs(Section, { children: [hasNoLocationSearchResults && (_jsx(Text, Object.assign({ variant: TextVariant.L1SB }, { children: _jsx(FormattedMessage, { id: "SearchBarSheet.Location.noLocationFound" }) }))), _jsxs(MobileSearchInputWrapper, { children: [_jsx(Input, { inputRef: mobileLocationInputRef, onChange: event => {
                                    dispatch(setLocationSearchInProgress(true));
                                    onChangeLocation(event.target.value);
                                }, disabled: !!isLocation, placeholder: placeholder }), isLocation && (_jsx(ClearIconWrapper, { children: _jsx(Button2024, { iconOnLeft: IconVariant.X_Close, onClick: onResetLocation, sizeVariant: ButtonSizeVariant.Small, variant: ButtonVariant.Outlined }) }))] }), _jsxs(Content, { children: [showAnimatedDots && _jsx(AnimatedDots, {}), hasLocationSearchResults &&
                                hasSearchInput &&
                                !showAnimatedDots &&
                                (locationResults === null || locationResults === void 0 ? void 0 : locationResults.map((searchResult) => (_jsx(Button2024, { label: searchResult.label, onClick: () => {
                                        onClickSearchResult(searchResult.id);
                                        openNextSearchBarSheet();
                                    }, variant: ButtonVariant.Link, iconOnLeft: IconVariant.LocationPin, sizeVariant: ButtonSizeVariant.Large }, searchResult.id)))), _jsx(Button2024, { label: intl.formatMessage({
                                    id: 'SearchBarSheet.Location.flexible',
                                }), iconOnLeft: IconVariant.Image, onClick: (event) => {
                                    event.preventDefault();
                                    onSetFlexibleLocation();
                                    openNextSearchBarSheet();
                                }, variant: ButtonVariant.Link, sizeVariant: ButtonSizeVariant.Large }), _jsx(Button2024, { label: intl.formatMessage({
                                    id: 'SearchBarSheet.Location.useMyLocation',
                                }), 
                                // targetRoute={Page.Search}
                                iconOnLeft: IconVariant.CurrentPosition, onClick: (event) => {
                                    event.preventDefault();
                                    onSetGeolocationSource(GeolocationSource.SearchBar);
                                    onSetShowRequestBrowserGeolocationModal(true);
                                }, variant: ButtonVariant.Link, sizeVariant: ButtonSizeVariant.Large })] })] }), !hasLocationSearchResults && (_jsxs(Section, { children: [_jsx(Title, Object.assign({ variant: TextVariant.L1SB }, { children: _jsx(FormattedMessage, { id: "SearchBarSheet.Location.discoverCountries" }) })), _jsx(CountryButtonWrapper, { children: shuffledRegions.map((location) => (_jsx(Button2024, Object.assign({ onClick: (event) => {
                                event.preventDefault();
                                onSetLocation(Object.assign(Object.assign({}, location), { name: intl.formatMessage({ id: location.name }) }));
                                openNextSearchBarSheet();
                            }, variant: ButtonVariant.Link, sizeVariant: ButtonSizeVariant.Large }, { children: _jsxs(CountryButtonContentWrapper, { children: [_jsx("img", { src: location.image, height: 32, width: 32, alt: intl.formatMessage({ id: 'TopBar.menuItemImageAlt' }), "aria-hidden": true }), intl.formatMessage({ id: location.name })] }) }), location.placeId))) })] }))] }));
};
export default SearchBarSheetLocation;
