var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import moment from 'moment';
import { FormProvider, useForm } from 'react-hook-form';
import styled from 'styled-components/macro';
import { loadStripe } from '@stripe/stripe-js';
import { isBrowser } from '@nomady/shared/utils/env';
import { MARKETPLACE_CURRENCIES, MARKETPLACE_CURRENCY, } from '@nomady/shared/config';
import { AVAILABLE_GIFT_CARD_VALUES, GIFT_CARD_SALE_TYPE, } from '@nomady/shared/gift-card/config';
import { stripePublishableKey } from '@nomady/shared/stripe-config';
import Button, { ButtonColorVariant, } from '../../../styled-components/Button/Button';
import FormErrorMessage from '../../../styled-components/FormErrorMessage/FormErrorMessage';
import SelectInput from '../../SelectInput/SelectInput';
import SteppedNumberInput, { SteppedNumberInputVariant, } from '../../SteppedNumberInput/SteppedNumberInput';
import Total from '../../Total/Total';
import Text, { TextVariant } from '../../Text/Text';
import SecureCheckoutTag from '../../SecureCheckoutTag/SecureCheckoutTag';
import LegalAgreement from '../../LegalAgreement/LegalAgreement';
import { localeSelector } from '../../../slices/UISlice';
import { createCheckoutSession } from '../../../util/api';
import { ColorVariant } from '../../../theme';
import { isApp } from '../../../util/deviceContext';
const hasFacebookPixelEnabled = isBrowser() && !!(window === null || window === void 0 ? void 0 : window.fbq);
const Form = styled.form ``;
const FormRow = styled.div `
  margin-bottom: 10px;
`;
const ChristmasText = styled.div `
  display: flex;
  margin-bottom: 10px;
`;
const BoldText = styled(Text) `
  font-weight: 700;
`;
const GiftCardShopItemForm = ({ type, }) => {
    const intl = useIntl();
    const locale = useSelector(localeSelector);
    const methods = useForm({
        defaultValues: { currency: MARKETPLACE_CURRENCY },
    });
    const { register, handleSubmit, formState: { errors }, watch, } = methods;
    const watchCurrency = watch('currency');
    const [valueIndex, setValueIndex] = useState(AVAILABLE_GIFT_CARD_VALUES[0]);
    const [quantity, setQuantity] = useState(1);
    const [currency, setCurrency] = useState(MARKETPLACE_CURRENCY);
    const [purchaseInProgress, setPurchaseInProgress] = useState(false);
    const [error, setError] = useState();
    //   const value = AVAILABLE_GIFT_CARD_VALUES[valueIndex];
    React.useEffect(() => {
        const subscription = watch((value, { name, type }) => {
            if (name === 'currency') {
                setCurrency(value.currency);
            }
            if (name === 'quantity') {
                setQuantity(value.quantity || 1);
            }
            if (name === 'value') {
                setValueIndex(value.value || 0);
            }
        });
        return () => subscription.unsubscribe();
    }, [watchCurrency]);
    const onCreateCheckoutSession = (value, quantity, type, currency, locale) => __awaiter(void 0, void 0, void 0, function* () {
        const stripe = yield loadStripe(stripePublishableKey);
        if (hasFacebookPixelEnabled) {
            console.info('track initiatecheckout');
            window.fbq('track', 'InitiateCheckout');
        }
        const response = yield createCheckoutSession(value, quantity, type, currency, locale);
        const session = yield response.json();
        if (stripe) {
            if (isApp) {
                window.location.href = session.url;
            }
            else {
                const result = yield stripe.redirectToCheckout({
                    sessionId: session.id,
                });
                if (result.error) {
                    setError(result.error.message);
                    console.error(result.error);
                }
            }
        }
    });
    const onSuccess = (data) => __awaiter(void 0, void 0, void 0, function* () {
        setPurchaseInProgress(true);
        try {
            yield onCreateCheckoutSession(valueIndex * 100, quantity, type, currency, locale);
        }
        catch (error) {
            setPurchaseInProgress(false);
            //   setError(error?.toString());
        }
        setPurchaseInProgress(false);
    });
    const _a = register('currency'), { ref: currencyRef } = _a, currencyProps = __rest(_a, ["ref"]);
    const valueProps = register('value');
    const quantityProps = register('quantity');
    return (_jsx(FormProvider, Object.assign({}, methods, { children: _jsxs(Form, Object.assign({ onSubmit: handleSubmit(onSuccess) }, { children: [_jsx(FormRow, { children: _jsx(SelectInput, Object.assign({ label: intl.formatMessage({
                            id: 'GiftCardShopItemForm.currencyLabel',
                        }), id: "currency", autoComplete: "currency" }, currencyProps, { inputRef: currencyRef, error: errors === null || errors === void 0 ? void 0 : errors.currency, options: MARKETPLACE_CURRENCIES.map(currency => ({
                            value: currency,
                            label: intl.formatMessage({
                                id: `GiftCardShopItemForm.currencyLabel.${currency}`,
                            }),
                        })) })) }), _jsxs(FormRow, { children: [_jsx(SteppedNumberInput, Object.assign({}, valueProps, { value: valueIndex, label: intl.formatMessage({
                                id: 'GiftCardShopItemForm.valueLabel',
                            }, { currency }), min: AVAILABLE_GIFT_CARD_VALUES[0], max: AVAILABLE_GIFT_CARD_VALUES[AVAILABLE_GIFT_CARD_VALUES.length - 1], steps: AVAILABLE_GIFT_CARD_VALUES, variant: SteppedNumberInputVariant.White, valueSuffix: currency })), _jsx(FormRow, { children: _jsx(SteppedNumberInput, Object.assign({}, quantityProps, { value: quantity, label: intl.formatMessage({
                                    id: 'GiftCardShopItemForm.quantityLabel',
                                }), min: 1, max: 30, variant: SteppedNumberInputVariant.White })) })] }), _jsx(Total, { currency: currency, totalPrice: quantity * valueIndex }), _jsx(LegalAgreement, { agreeButtonLabelId: "GiftCardShopItemForm.primaryButtonLabel", showGiftCardTerms: true }), !!error && _jsx(FormErrorMessage, { id: error }), type === GIFT_CARD_SALE_TYPE.PHYSICAL_GIFT_CARD &&
                    moment().utc().isAfter(moment('2023-11-30T23:59:59.000')) &&
                    moment().utc().isBefore(moment('2023-12-26T00:00:00.000')) && (_jsx(ChristmasText, { children: _jsx(BoldText, Object.assign({ variant: TextVariant.B50, colorVariant: ColorVariant.Blood }, { children: intl.formatMessage({
                            id: 'GiftCardShopItemForm.christmasDelivery',
                        }) })) })), _jsx(Button, { dataTestId: "PasswordRecoveryPage_recoveryButton", label: intl.formatMessage({
                        id: 'GiftCardShopItemForm.primaryButtonLabel',
                    }), colorVariant: ButtonColorVariant.Forest, type: "submit", inProgress: purchaseInProgress }), _jsx(SecureCheckoutTag, {})] })) })));
};
export default GiftCardShopItemForm;
