var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createSlice } from '@reduxjs/toolkit';
import { isPrivileged, TRANSITION_CONFIRM_PAYMENT, TRANSITION_CONFIRM_PAYMENT_FOR_INSTANT_BOOKING, TRANSITION_PAY_FULL_AMOUNT_WITH_GIFT_CODE, TRANSITION_PAY_FULL_AMOUNT_WITH_GIFT_CODE_FOR_INSTANT_BOOKING, TRANSITION_REQUEST_PAYMENT, } from '@nomady/shared/utils/transaction';
import { denormalisedResponseEntities } from '@nomady/shared/utils/data';
import { nightsBetween } from '@nomady/shared/utils/dates';
import { convertMoneyToNumber, isMoney } from '@nomady/shared/utils/currency';
import { PaymentMode } from '@nomady/shared/config';
import config from '../../config';
import { confirmPaymentPrivileged, initiatePrivileged, transitionPrivileged, } from '../../util/api';
import { storeData } from './utils/sessionStorage';
import { STORAGE_KEY } from './CheckoutPage';
import { fetchMessages } from '../TransactionPage/TransactionPage.duck';
import { recordAnalyticsEvent, } from '../../ducks/Analytics.duck';
import { isApp } from '../../util/deviceContext';
const initialState = {
    listing: undefined,
    bookingData: {
        seatQuantity: 1,
        adultQuantity: 2,
        teenQuantity: 0,
        childQuantity: 0,
    },
    bookingDates: undefined,
    transaction: undefined,
    providerStripeAccountId: undefined,
    giftCardInputNotEmpty: false,
    paymentMode: isApp ? PaymentMode.Legacy : PaymentMode.Custom,
};
export const CheckoutPageSlice = createSlice({
    name: 'app/CheckoutPage',
    initialState,
    reducers: {
        resetCheckout: () => initialState,
        setInitialValues: (state, action) => {
            const values = action.payload;
            state.listing = values.listing;
            state.bookingData = values.bookingData;
            state.bookingDates = values.bookingDates;
            state.providerStripeAccountId = values.providerStripeAccountId;
            if (values.transaction) {
                state.transaction = values.transaction;
            }
        },
        setCustomerCommissionDiscountCode: (state, action) => {
            state.customerCommissionDiscountCode = action.payload;
        },
        addGiftCard: (state, action) => {
            state.giftCards = [...(state.giftCards || []), action.payload];
        },
        removeGiftCard: (state, action) => {
            state.giftCards = [
                ...(state.giftCards || []).filter(code => code.id !== action.payload),
            ];
        },
        resetGiftCards: state => {
            state.giftCards = [];
        },
        setGiftCardInputNotEmpty: (state, action) => {
            state.giftCardInputNotEmpty = action.payload;
        },
        setTransaction: (state, action) => {
            state.transaction = action.payload;
        },
        setPaymentMode: (state, action) => {
            state.paymentMode = action.payload;
        },
    },
});
export const { setCustomerCommissionDiscountCode, addGiftCard, removeGiftCard, resetGiftCards, resetCheckout, setGiftCardInputNotEmpty, setPaymentMode, } = CheckoutPageSlice.actions;
export const togglePaymentMode = () => (dispatch, getState) => __awaiter(void 0, void 0, void 0, function* () {
    const state = getState();
    const currentPaymentMode = state.CheckoutPage.paymentMode;
    dispatch(setPaymentMode(currentPaymentMode === PaymentMode.Custom
        ? PaymentMode.Legacy
        : PaymentMode.Custom));
    if (currentPaymentMode === PaymentMode.Legacy) {
        dispatch(resetGiftCards());
    }
});
export const setInitialValues = (initialValues) => (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
    storeData(STORAGE_KEY, {
        bookingData: initialValues.bookingData,
        bookingDates: initialValues.bookingDates,
        listing: initialValues.listing,
        providerStripeAccountId: initialValues.providerStripeAccountId,
        transaction: initialValues.transaction,
    });
    dispatch(CheckoutPageSlice.actions.setInitialValues(initialValues));
});
export const setTransaction = (transaction) => (dispatch, getState) => __awaiter(void 0, void 0, void 0, function* () {
    const state = getState();
    const initialValues = state.CheckoutPage;
    storeData(STORAGE_KEY, {
        bookingData: initialValues.bookingData,
        bookingDates: initialValues.bookingDates,
        listing: initialValues.listing,
        providerStripeAccountId: initialValues.providerStripeAccountId,
        transaction,
    });
    dispatch(CheckoutPageSlice.actions.setTransaction(transaction));
});
export const initiateOrder = (orderParams, instantBooking, transactionId) => (dispatch, getState, sdk) => __awaiter(void 0, void 0, void 0, function* () {
    const transactionAlreadyInititated = !!transactionId;
    let transition = TRANSITION_REQUEST_PAYMENT;
    const isFullGiftCardTransition = orderParams.protectedData.payins.stripePayment === 0;
    if (isFullGiftCardTransition) {
        if (instantBooking) {
            transition =
                TRANSITION_PAY_FULL_AMOUNT_WITH_GIFT_CODE_FOR_INSTANT_BOOKING;
        }
        else {
            transition = TRANSITION_PAY_FULL_AMOUNT_WITH_GIFT_CODE;
        }
    }
    const isPrivilegedTransition = isPrivileged(transition);
    const { paymentMode } = orderParams.protectedData;
    const bookingData = {
        startDate: orderParams.bookingStart,
        endDate: orderParams.bookingEnd,
    };
    const queryParams = {
        include: ['booking', 'provider'],
        expand: true,
    };
    const handleSuccess = (response) => {
        const entities = denormalisedResponseEntities(response);
        const order = entities[0];
        return order;
    };
    const handleError = (e) => {
        throw e;
    };
    if (transactionAlreadyInititated) {
        const bodyParams = {
            id: transactionId,
            transition,
            params: orderParams,
        };
        if (isPrivilegedTransition) {
            // transition privileged
            return transitionPrivileged({
                isSpeculative: false,
                bookingData,
                bodyParams,
                queryParams,
            })
                .then(handleSuccess)
                .catch(handleError);
        }
        // transition non-privileged
        return sdk.transactions
            .transition(bodyParams, queryParams)
            .then(handleSuccess)
            .catch(handleError);
    }
    const bodyParams = {
        processAlias: paymentMode === PaymentMode.Custom
            ? config.customBookingProcessAlias
            : config.legacyBookingProcessAlias,
        transition,
        params: orderParams,
    };
    if (isPrivilegedTransition) {
        // initiate privileged
        return initiatePrivileged({
            isSpeculative: false,
            bookingData,
            bodyParams,
            queryParams,
        })
            .then(handleSuccess)
            .catch(handleError);
    }
    // initiate non-privileged
    return sdk.transactions.initiate(bodyParams, queryParams)
        .then(handleSuccess)
        .catch(handleError);
});
export const confirmPayment = (transactionId, instantBooking) => (dispatch, getState, sdk) => __awaiter(void 0, void 0, void 0, function* () {
    var _a;
    const transition = instantBooking
        ? TRANSITION_CONFIRM_PAYMENT_FOR_INSTANT_BOOKING
        : TRANSITION_CONFIRM_PAYMENT;
    const bodyParams = {
        id: transactionId,
        transition,
        params: {},
    };
    try {
        yield confirmPaymentPrivileged({ bodyParams });
    }
    catch (error) {
        const response = yield ((_a = error === null || error === void 0 ? void 0 : error.apiResponse) === null || _a === void 0 ? void 0 : _a.json());
        return response === null || response === void 0 ? void 0 : response.error;
    }
    return 'success';
});
export const sendMessageToProvider = (message, transactionId) => (dispatch, getState, sdk) => __awaiter(void 0, void 0, void 0, function* () {
    const response = (yield sdk.messages.send({
        content: message,
        transactionId,
    }, { expand: true }));
    dispatch(fetchMessages(transactionId));
    return response.data.data;
});
export const recordAnalytics = (transaction, listing, bookingDates) => (dispatch) => {
    try {
        if (isMoney(transaction.attributes.payinTotal) &&
            (bookingDates === null || bookingDates === void 0 ? void 0 : bookingDates.bookingStart) &&
            (bookingDates === null || bookingDates === void 0 ? void 0 : bookingDates.bookingEnd)) {
            const revenue = convertMoneyToNumber(transaction.attributes.payinTotal);
            const analyticsParams = {
                value: transaction.attributes.payinTotal.amount,
                transaction: {
                    revenue,
                    id: transaction.id.uuid,
                    provider_id: listing.author.id.uuid,
                    listing_id: listing.id.uuid,
                    listing_title: listing.attributes.title,
                    nights: nightsBetween(bookingDates.bookingStart, bookingDates.bookingEnd),
                },
            };
            dispatch(recordAnalyticsEvent('booking', 'bookings', analyticsParams));
        }
    }
    catch (error) {
        console.error('Could not dispatch analytics event');
        console.error(error);
    }
};
export default CheckoutPageSlice.reducer;
export const requestPaymentTransitionTimeSelector = (state) => {
    var _a, _b;
    const { transaction } = state.CheckoutPage;
    if (transaction) {
        return (((_b = (_a = transaction.attributes) === null || _a === void 0 ? void 0 : _a.transitions.find(transition => transition.transition === TRANSITION_REQUEST_PAYMENT)) === null || _b === void 0 ? void 0 : _b.createdAt) || undefined);
    }
    return undefined;
};
export const paymentModeSelector = (state) => state.CheckoutPage.paymentMode;
