import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import styled from 'styled-components/macro';
import moment from 'moment';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import Button, { ButtonColorVariant, ButtonOutlineVariant, ButtonSizeVariant, } from '../../../styled-components/Button/Button';
import DateRangeCalendar from '../../../styled-components/DateRangeCalendar/DateRangeCalendar';
import { setBookingEnd, setBookingStart } from '../ListingPageSlice';
import Text, { TextVariant } from '../../../styled-components/Text/Text';
import { ColorVariant } from '../../../theme';
const ContentWrap = styled.div `
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const ButtonArea = styled.div `
  width: 100%;
  margin-top: 5px;
  display: flex;
  flex-direction: row;
  justify-content: ${props => props.showResetButton ? 'space-between' : 'flex-end'};
`;
const BookingDatesSelection = ({ onClose }) => {
    var _a;
    const intl = useIntl();
    const dispatch = useDispatch();
    const [isInvalidRange, setIsInvalidRange] = useState(false);
    const { bookingEnd, bookingStart, listing, timeSlots, bookingData: { seatQuantity }, } = useSelector((state) => state.ListingPage);
    const onClickSaveDates = () => {
        if (onClose)
            onClose();
    };
    const onClickResetDates = () => {
        dispatch(setBookingStart(undefined));
        dispatch(setBookingEnd(undefined));
        setIsInvalidRange(false);
    };
    const showResetButton = !!bookingStart;
    const { minBookableNights, maxBookableNights, seats: totalSeats, } = ((_a = listing === null || listing === void 0 ? void 0 : listing.attributes) === null || _a === void 0 ? void 0 : _a.publicData) || {};
    const handleChangeRange = (start, end) => {
        const nightsSelected = end && start && end.diff(start, 'days');
        if (nightsSelected) {
            if (minBookableNights && nightsSelected < minBookableNights) {
                setIsInvalidRange(true);
            }
            else if (maxBookableNights && nightsSelected > maxBookableNights) {
                setIsInvalidRange(true);
            }
            else {
                setIsInvalidRange(false);
                dispatch(setBookingEnd(end === null || end === void 0 ? void 0 : end.toDate()));
            }
        }
        if (!!bookingEnd && !end)
            dispatch(setBookingEnd(undefined));
        dispatch(setBookingStart(start === null || start === void 0 ? void 0 : start.toDate()));
    };
    if (!timeSlots) {
        return null;
    }
    return (_jsxs(ContentWrap, Object.assign({ "data-testid": "BookingDatesSelection" }, { children: [_jsx(DateRangeCalendar, { onChangeRange: handleChangeRange, startDay: bookingStart ? moment(bookingStart) : undefined, endDay: bookingEnd ? moment(bookingEnd) : undefined, showSingleMonthOnDesktop: true, timeSlots: timeSlots, totalSeats: totalSeats, requestedSeats: seatQuantity }), isInvalidRange && (_jsx(Text, Object.assign({ variant: TextVariant.B50, colorVariant: ColorVariant.Blood }, { children: intl.formatMessage({ id: 'ListingPage.invalidRange' }, { minBookableNights, maxBookableNights }) }))), _jsxs(ButtonArea, Object.assign({ showResetButton: showResetButton }, { children: [showResetButton && (_jsx("div", { children: _jsx(Button, { onClick: onClickResetDates, label: intl.formatMessage({
                                id: 'ListingPage.reset',
                            }), sizeVariant: ButtonSizeVariant.Small, outlineVariant: ButtonOutlineVariant.Outlined }) })), _jsx("div", { children: _jsx(Button, { dataTestId: "BookingDatesSelection_SaveButton", disabled: !bookingEnd || !bookingStart, onClick: onClickSaveDates, label: intl.formatMessage({
                                id: 'ListingPage.saveDates',
                            }), sizeVariant: ButtonSizeVariant.Small, colorVariant: ButtonColorVariant.Forest }) })] }))] })));
};
export default BookingDatesSelection;
