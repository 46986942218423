import { AccessType, AccommodationAmenity, Environment, ArrivalType, DefaultAmenity, SummerActivity, WinterActivity, CampStyle, CampCategory, ToiletType, ShowerType, CleaningFeeType, CancellationType, FireplaceType, CateringType, VisitorsTaxType, ReferralOption, DogPolicy, GroundType, MarkerType, PowerSupplyPayment, PowerSupplyFeature, FirewoodPayment, } from './types';
import { MarketplaceCurrency, MarketplaceLocale } from './config';
import { types as sdkTypes } from './utils/sdkLoader';
const { LatLng, LatLngBounds } = sdkTypes;
export const getDeeplLocale = (locale) => {
    return (locale === null || locale === void 0 ? void 0 : locale.slice(0, 2)) || undefined;
};
export const getRegionOfLocale = (locale) => {
    return locale === null || locale === void 0 ? void 0 : locale.slice(3, 5);
};
const CHF_CURRENCY_REGION = 'CH';
export const getDefaultLocaleCurrency = (locale) => {
    if (getRegionOfLocale(locale) === CHF_CURRENCY_REGION) {
        return MarketplaceCurrency.CHF;
    }
    return MarketplaceCurrency.EUR;
};
export const LOCALE_CODES = [
    {
        key: MarketplaceLocale['en-US'],
        label: 'English (Global)',
    },
    {
        key: MarketplaceLocale['de-DE'],
        label: 'Deutsch (Deutschland)',
    },
    {
        key: MarketplaceLocale['fr-FR'],
        label: 'Français (France)',
    },
    {
        key: MarketplaceLocale['it-IT'],
        label: 'Italiano (Italia)',
    },
];
export const DEFAULT_LOCALE = MarketplaceLocale['en-US'];
export const MAILCHIMP_LOCALE_GROUPS = {
    [MarketplaceLocale['de-CH']]: 0,
    [MarketplaceLocale['en-US']]: 1,
    [MarketplaceLocale['it-CH']]: 2,
    [MarketplaceLocale['fr-CH']]: 3,
    [MarketplaceLocale['de-DE']]: 4,
    [MarketplaceLocale['de-AT']]: 5,
    [MarketplaceLocale['it-IT']]: 6,
    [MarketplaceLocale['fr-FR']]: 7,
};
export const amenities = [
    {
        key: DefaultAmenity.kitchen,
        label: 'MarketplaceConfig.kitchen',
    },
    {
        key: DefaultAmenity.picNicTable,
        label: 'MarketplaceConfig.picNicTable',
    },
    {
        key: DefaultAmenity.shelter,
        label: 'MarketplaceConfig.shelter',
    },
    {
        key: DefaultAmenity.trash,
        label: 'MarketplaceConfig.trash',
    },
    {
        key: DefaultAmenity.disposeTrashSelf,
        label: 'MarketplaceConfig.disposeTrashSelf',
    },
    {
        key: DefaultAmenity.wifi,
        label: 'MarketplaceConfig.wifi',
    },
    {
        key: DefaultAmenity.reception,
        label: 'MarketplaceConfig.reception',
    },
    {
        key: DefaultAmenity.dishwashing,
        label: 'MarketplaceConfig.dishwashing',
    },
    {
        key: DefaultAmenity.greywater,
        label: 'MarketplaceConfig.greywater',
    },
];
export const accommodationAmenities = [
    ...amenities,
    {
        key: AccommodationAmenity.heating,
        label: 'MarketplaceConfig.heating',
    },
    {
        key: AccommodationAmenity.bedding,
        label: 'MarketplaceConfig.bedding',
    },
    {
        key: AccommodationAmenity.ownSleepingBag,
        label: 'MarketplaceConfig.ownSleepingBag',
    },
];
export const environment = [
    {
        key: Environment.forest,
        label: 'MarketplaceConfig.forest',
    },
    {
        key: Environment.lawn,
        label: 'MarketplaceConfig.lawn',
    },
    {
        key: Environment.river,
        label: 'MarketplaceConfig.river',
    },
    {
        key: Environment.lake,
        label: 'MarketplaceConfig.lake',
    },
    {
        key: Environment.mountains,
        label: 'MarketplaceConfig.mountains',
    },
    {
        key: Environment.farm,
        label: 'MarketplaceConfig.farm',
    },
    {
        key: Environment.street,
        label: 'MarketplaceConfig.street',
    },
    {
        key: Environment.shadowy,
        label: 'MarketplaceConfig.shadowy',
    },
];
export const activities = [
    {
        key: SummerActivity.bike,
        label: 'MarketplaceConfig.bike',
    },
    {
        key: SummerActivity.hike,
        label: 'MarketplaceConfig.hike',
    },
    {
        key: SummerActivity.climb,
        label: 'MarketplaceConfig.climb',
    },
    {
        key: SummerActivity.paddle,
        label: 'MarketplaceConfig.paddle',
    },
    {
        key: SummerActivity.swim,
        label: 'MarketplaceConfig.swim',
    },
    {
        key: SummerActivity.fishing,
        label: 'MarketplaceConfig.fishing',
    },
    {
        key: SummerActivity.animalWatching,
        label: 'MarketplaceConfig.animalWatching',
    },
];
export const winterActivities = [
    {
        key: WinterActivity.skiing,
        label: 'MarketplaceConfig.skiing',
    },
    {
        key: WinterActivity.langlauf,
        label: 'MarketplaceConfig.langlauf',
    },
    {
        key: WinterActivity.skiTouring,
        label: 'MarketplaceConfig.skiTouring',
    },
    {
        key: WinterActivity.snowShoeHiking,
        label: 'MarketplaceConfig.snowShoeHiking',
    },
    {
        key: WinterActivity.hockey,
        label: 'MarketplaceConfig.hockey',
    },
    {
        key: WinterActivity.iceRink,
        label: 'MarketplaceConfig.iceRink',
    },
    {
        key: WinterActivity.sledding,
        label: 'MarketplaceConfig.sleeding',
    },
    {
        key: WinterActivity.iglooBuilding,
        label: 'MarketplaceConfig.iglooBuilding',
    },
];
export const categories = [
    {
        key: CampCategory.camping,
        label: 'MarketplaceConfig.camping',
    },
    {
        key: CampCategory.accommodation,
        label: 'MarketplaceConfig.accommodation',
    },
];
export const categoryCampStyles = {
    [CampCategory.camping]: [
        { key: CampStyle.tent, label: 'MarketplaceConfig.tent' },
        { key: CampStyle.smallVehicle, label: 'MarketplaceConfig.smallVehicle' },
        { key: CampStyle.mediumVehicle, label: 'MarketplaceConfig.mediumVehicle' },
        { key: CampStyle.largeVehicle, label: 'MarketplaceConfig.largeVehicle' },
        {
            key: CampStyle.carWithRooftopTent,
            label: 'MarketplaceConfig.carWithRooftopTent',
        },
        { key: CampStyle.caravan, label: 'MarketplaceConfig.caravan' },
    ],
    [CampCategory.accommodation]: [
        { key: CampStyle.hut, label: 'MarketplaceConfig.hut' },
        { key: CampStyle.glamping, label: 'MarketplaceConfig.glamping' },
        { key: CampStyle.shelter, label: 'MarketplaceConfig.shelter' },
    ],
};
export const campStyles = Object.values(categoryCampStyles).flat();
export const parseInputToApi = (value) => {
    let returnValue = false;
    if (Array.isArray(value) && value.includes('true')) {
        returnValue = true;
    }
    return returnValue;
};
export const formatApiToInput = (value) => {
    const returnValue = [(!!value).toString()];
    return returnValue;
};
export const yesCheckbox = [
    {
        key: 'true',
        label: 'MarketplaceConfig.yes',
        parse: parseInputToApi,
        format: formatApiToInput,
    },
];
export const giftCardTermsAccepted = [
    {
        key: 'true',
        label: 'MarketplaceConfig.acceptGiftCardTerms',
    },
];
export const toiletTypes = [
    {
        key: ToiletType.regular,
        label: 'MarketplaceConfig.regularToilet',
    },
    {
        key: ToiletType.portable,
        label: 'MarketplaceConfig.portableToilet',
    },
    {
        key: ToiletType.atCamp,
        label: 'MarketplaceConfig.toiletAtCamp',
    },
];
export const showerTypes = [
    {
        key: ShowerType.regular,
        label: 'MarketplaceConfig.regularShower',
    },
    {
        key: ShowerType.outdoor,
        label: 'MarketplaceConfig.outdoorShower',
    },
];
export const fireplaceTypes = [
    {
        key: FireplaceType.fireplaceWithGrate,
        label: 'MarketplaceConfig.fireplaceWithGrate',
    },
];
export const firewoodPaymentTypes = [
    {
        key: FirewoodPayment.firewoodInclusive,
        label: 'MarketplaceConfig.firewoodInclusive',
    },
    {
        key: FirewoodPayment.firewoodSaleOnSite,
        label: 'MarketplaceConfig.firewoodSaleOnSite',
    },
    {
        key: FirewoodPayment.firewoodPurchasable,
        label: 'MarketplaceConfig.firewoodPurchasable',
    },
    {
        key: FirewoodPayment.firewoodGuestBringsAlong,
        label: 'MarketplaceConfig.firewoodGuestBringsAlong',
    },
];
export const powerSupplyFeatures = Object.values(PowerSupplyFeature).map(key => ({
    key,
    label: `MarketplaceConfig.powerSupplyType.${key}`,
}));
export const powerSupplyPayment = Object.values(PowerSupplyPayment).map(key => ({
    key,
    label: `MarketplaceConfig.powerSupplyType.${key}`,
}));
export const cateringTypes = [
    {
        key: CateringType.bakery,
        label: 'MarketplaceConfig.bakery',
    },
    {
        key: CateringType.farmShop,
        label: 'MarketplaceConfig.farmShop',
    },
    {
        key: CateringType.cafe,
        label: 'MarketplaceConfig.cafe',
    },
    {
        key: CateringType.restaurant,
        label: 'MarketplaceConfig.restaurant',
    },
    {
        key: CateringType.alp,
        label: 'MarketplaceConfig.alp',
    },
];
export const groundTypes = [
    { key: GroundType.lawn, label: 'MarketplaceConfig.lawn' },
    {
        key: GroundType.asphalt,
        label: 'MarketplaceConfig.asphalt',
    },
    {
        key: GroundType.gravel,
        label: 'MarketplaceConfig.gravel',
    },
];
export const accessTypes = [
    {
        key: AccessType.regularAccess,
        label: 'MarketplaceConfig.regularAccess',
    },
    {
        key: AccessType.extraGroundClearance,
        label: 'MarketplaceConfig.extraGroundClearance',
    },
    {
        key: AccessType.fourByFour,
        label: 'MarketplaceConfig.fourByFour',
    },
];
export const visitorsTaxTypes = [
    {
        key: VisitorsTaxType.perPersonAndPerNight,
        label: 'MarketplaceConfig.taxesPerPersonAndPerNight',
    },
    {
        key: VisitorsTaxType.communal,
        label: 'MarketplaceConfig.communalTaxes',
    },
    {
        key: VisitorsTaxType.none,
        label: 'MarketplaceConfig.noTaxes',
    },
];
export const cleaningFeeTypes = [
    {
        key: CleaningFeeType.included,
        label: 'MarketplaceConfig.included',
    },
    {
        key: CleaningFeeType.selfClean,
        label: 'MarketplaceConfig.selfClean',
    },
    {
        key: CleaningFeeType.cleaningFeePricing,
        label: 'MarketplaceConfig.cleaningFee',
    },
];
export const minNightsOptions = [
    { key: 0, label: 'MarketplaceConfig.noLimit' },
    { key: 1, label: 'MarketplaceConfig.oneDay' },
    { key: 2, label: 'MarketplaceConfig.twoDays' },
    { key: 3, label: 'MarketplaceConfig.threeDays' },
    { key: 4, label: 'MarketplaceConfig.fourDays' },
    { key: 5, label: 'MarketplaceConfig.fiveDays' },
    { key: 6, label: 'MarketplaceConfig.sixDays' },
    { key: 7, label: 'MarketplaceConfig.sevenDays' },
];
export const maxNightsOptions = [
    { key: 365, label: 'MarketplaceConfig.noLimit' },
    { key: 1, label: 'MarketplaceConfig.oneDay' },
    { key: 2, label: 'MarketplaceConfig.twoDays' },
    { key: 3, label: 'MarketplaceConfig.threeDays' },
    { key: 4, label: 'MarketplaceConfig.fourDays' },
    { key: 5, label: 'MarketplaceConfig.fiveDays' },
    { key: 6, label: 'MarketplaceConfig.sixDays' },
    { key: 7, label: 'MarketplaceConfig.oneWeek' },
    { key: 14, label: 'MarketplaceConfig.twoWeeks' },
    { key: 31, label: 'MarketplaceConfig.oneMonth' },
];
export const accountType = [
    { key: 'provider', label: 'MarketplaceConfig.provider' },
    { key: 'customer', label: 'MarketplaceConfig.customer' },
    {
        key: 'customer_and_provider',
        label: 'MarketplaceConfig.customerAndProvider',
    },
];
export const instantBookingTypes = [
    {
        key: true,
        label: 'EditListingRulesForm.instantBookingYes',
    },
    {
        key: false,
        label: 'EditListingRulesForm.instantBookingNo',
    },
];
export const CANCELLATION_TYPE = {
    SUPER_FLEX: { key: CancellationType.superFlex, days: 0 },
    FLEX: { key: CancellationType.flex, days: 1 },
    EASY: { key: CancellationType.easy, days: 3 },
    MEDIUM: { key: CancellationType.medium, days: 10 },
    STRICT: { key: CancellationType.strict, days: 30 },
    NONE: { key: CancellationType.none, days: null },
};
export const cancellationTypes = [
    Object.assign(Object.assign({}, CANCELLATION_TYPE.SUPER_FLEX), { label: 'MarketplaceConfig.superFlexCancel', iconName: 'Rainbow' }),
    Object.assign(Object.assign({}, CANCELLATION_TYPE.FLEX), { label: 'MarketplaceConfig.flexCancel', iconName: 'Umbrella' }),
    Object.assign(Object.assign({}, CANCELLATION_TYPE.EASY), { label: 'MarketplaceConfig.easyCancel', iconName: 'Clock' }),
    Object.assign(Object.assign({}, CANCELLATION_TYPE.MEDIUM), { label: 'MarketplaceConfig.mediumCancel', iconName: 'Clock' }),
    Object.assign(Object.assign({}, CANCELLATION_TYPE.STRICT), { label: 'MarketplaceConfig.strictCancel', iconName: 'Clock' }),
    Object.assign(Object.assign({}, CANCELLATION_TYPE.NONE), { label: 'MarketplaceConfig.noneCancel', iconName: 'CircledCross' }),
];
export const arrivalTypes = [
    { key: ArrivalType.direct, label: 'MarketplaceConfig.directArrival' },
    { key: ArrivalType.reception, label: 'MarketplaceConfig.receptionArrival' },
];
export const dogPolicyTypes = [
    {
        key: DogPolicy.onRequest,
        label: 'MarketplaceConfig.dogPolicy.onRequest',
        condition: (instantBookingAvailable) => !instantBookingAvailable,
    },
    {
        key: DogPolicy.onlyOnLeash,
        label: 'MarketplaceConfig.dogPolicy.onlyOnLeash',
        condition: () => true,
    },
    {
        key: DogPolicy.extraDogFriendly,
        label: 'MarketplaceConfig.dogPolicy.extraDogFriendly',
        condition: () => true,
    },
];
export const mapMarkerTypes = [
    {
        key: MarkerType.camp,
        label: 'MarketplaceConfig.mapMarkerCamp',
    },
    {
        key: MarkerType.fireplace,
        label: 'MarketplaceConfig.mapMarkerFireplace',
    },
    {
        key: MarkerType.electricity,
        label: 'MarketplaceConfig.mapMarkerElectricity',
    },
    {
        key: MarkerType.parking,
        label: 'MarketplaceConfig.mapMarkerParking',
    },
    {
        key: MarkerType.toilet,
        label: 'MarketplaceConfig.mapMarkerToilet',
    },
    {
        key: MarkerType.water,
        label: 'MarketplaceConfig.mapMarkerWater',
    },
];
// Price filter configuration
// Note: unlike most prices this is not handled in subunits
export const priceFilterConfig = {
    min: 0,
    max: 300,
    step: 25,
};
// Activate booking dates filter on search page
export const dateRangeFilterConfig = {
    active: true,
};
// Activate keyword filter on search page
// NOTE: If you are ordering search results by distance the keyword search can't be used at the same time.
// You can turn off ordering by distance in config.js file
export const keywordFilterConfig = {
    active: true,
};
export var FilterDirection;
(function (FilterDirection) {
    FilterDirection["ASC"] = "ASC";
    FilterDirection["DESC"] = "DESC";
})(FilterDirection || (FilterDirection = {}));
export const sortKeyToSharetribeSortKeyWithDirection = (key) => {
    if (!key) {
        return undefined;
    }
    const sortOption = SORT_OPTIONS.find(option => option.key === key);
    if (!sortOption) {
        return key;
    }
    return `${sortOption.defaultDirection === FilterDirection.ASC ? '-' : ''}${sortOption.sharetribeSortKey}`;
};
export const distanceSortKey = 'distance';
export var SORT_KEY;
(function (SORT_KEY) {
    SORT_KEY["SCORE"] = "score";
    SORT_KEY["NEW"] = "new";
    SORT_KEY["OLD"] = "old";
    SORT_KEY["LOW_PRICE"] = "low_price";
    SORT_KEY["HIGH_PRICE"] = "high_price";
    SORT_KEY["RATING"] = "rating";
    SORT_KEY["DISTANCE"] = "distance";
})(SORT_KEY || (SORT_KEY = {}));
export const SORT_OPTIONS = [
    {
        key: SORT_KEY.SCORE,
        label: 'SearchPage.Order.score',
        sharetribeSortKey: 'meta_score',
        defaultDirection: FilterDirection.DESC,
    },
    {
        key: SORT_KEY.NEW,
        label: 'SearchPage.Order.new',
        sharetribeSortKey: 'createdAt',
        defaultDirection: FilterDirection.DESC,
    },
    {
        key: SORT_KEY.OLD,
        label: 'SearchPage.Order.old',
        sharetribeSortKey: 'createdAt',
        defaultDirection: FilterDirection.ASC,
    },
    {
        key: SORT_KEY.LOW_PRICE,
        label: 'SearchPage.Order.lowPrice',
        sharetribeSortKey: 'price',
        defaultDirection: FilterDirection.ASC,
    },
    {
        key: SORT_KEY.HIGH_PRICE,
        label: 'SearchPage.Order.highPrice',
        sharetribeSortKey: 'price',
        defaultDirection: FilterDirection.DESC,
    },
    {
        key: SORT_KEY.RATING,
        label: 'SearchPage.Order.rating',
        sharetribeSortKey: 'meta_rating',
        defaultDirection: FilterDirection.DESC,
    },
    {
        key: SORT_KEY.DISTANCE,
        label: 'SearchPage.Order.distance',
        sharetribeSortKey: 'distance',
        defaultDirection: FilterDirection.DESC,
    },
];
export const DEFAULT_SORT_KEY = SORT_OPTIONS[0].key;
export const seoSuffix = ' | Nomady';
export const defaultBounds = new LatLngBounds(new LatLng(51.550547923197854, 21.471264620172036), new LatLng(36.43999222920249, 0.3775146201720361));
// Maximum results per search result page
export const maxResultsPerSearchResultPage = 100;
export const mapStyles = [
    {
        elementType: 'geometry.fill',
        stylers: [
            {
                color: '#4d6059',
            },
        ],
    },
    {
        elementType: 'labels.text.stroke',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
    {
        featureType: 'administrative',
        elementType: 'labels.icon',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
    {
        featureType: 'administrative',
        elementType: 'labels.text.fill',
        stylers: [
            {
                color: '#f5f5f5',
            },
        ],
    },
    {
        featureType: 'administrative',
        elementType: 'labels.text.stroke',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
    {
        featureType: 'administrative.country',
        elementType: 'geometry.stroke',
        stylers: [
            {
                color: '#ffffff',
            },
        ],
    },
    {
        featureType: 'landscape.man_made',
        elementType: 'geometry.fill',
        stylers: [
            {
                color: '#42544d',
            },
        ],
    },
    {
        featureType: 'landscape.natural',
        stylers: [
            {
                color: '#7e3636',
            },
        ],
    },
    {
        featureType: 'landscape.natural',
        elementType: 'geometry',
        stylers: [
            {
                color: '#9f6464',
            },
        ],
    },
    {
        featureType: 'landscape.natural',
        elementType: 'geometry.fill',
        stylers: [
            {
                color: '#4d6059',
            },
        ],
    },
    {
        featureType: 'landscape.natural',
        elementType: 'labels.text.fill',
        stylers: [
            {
                color: '#f5f5f5',
            },
        ],
    },
    {
        featureType: 'landscape.natural.landcover',
        elementType: 'labels.text.stroke',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
    {
        featureType: 'poi',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
    {
        featureType: 'poi.attraction',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
    {
        featureType: 'poi.business',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
    {
        featureType: 'poi.government',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
    {
        featureType: 'poi.medical',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
    {
        featureType: 'poi.park',
        elementType: 'geometry.fill',
        stylers: [
            {
                color: '#385a4d',
            },
            {
                visibility: 'on',
            },
        ],
    },
    {
        featureType: 'poi.park',
        elementType: 'labels.icon',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
    {
        featureType: 'poi.park',
        elementType: 'labels.text.fill',
        stylers: [
            {
                color: '#f5f5f5',
            },
        ],
    },
    {
        featureType: 'poi.park',
        elementType: 'labels.text.stroke',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
    {
        featureType: 'poi.place_of_worship',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
    {
        featureType: 'poi.school',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
    {
        featureType: 'poi.sports_complex',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
    {
        featureType: 'road',
        elementType: 'geometry.fill',
        stylers: [
            {
                color: '#7f8d89',
            },
        ],
    },
    {
        featureType: 'road',
        elementType: 'geometry.stroke',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
    {
        featureType: 'road',
        elementType: 'labels.icon',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
    {
        featureType: 'road',
        elementType: 'labels.text.fill',
        stylers: [
            {
                color: '#f5f5f5',
            },
        ],
    },
    {
        featureType: 'road',
        elementType: 'labels.text.stroke',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
    {
        featureType: 'transit',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
    {
        featureType: 'transit',
        elementType: 'labels.icon',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
    {
        featureType: 'water',
        stylers: [
            {
                color: '#2b3638',
            },
        ],
    },
    {
        featureType: 'water',
        elementType: 'labels',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
];
export const REFFERAL_CUSTOM_OPTION = [
    ReferralOption.socialMedia,
    ReferralOption.socialMedia,
    ReferralOption.custom,
];
export const REFERRAL_OPTIONS = Object.values(ReferralOption);
