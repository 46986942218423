import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import styled from 'styled-components/macro';
import ListingCardOld, { ListingCardShadowVariant, } from '../ListingCardOld/ListingCardOld';
import SkeletonLoader, { SkeletonLoaderVariant, } from '../SkeletonLoader/SkeletonLoader';
const Listings = styled.div `
  display: flex;
  flex-direction: row;
  margin-bottom: 30px;
  padding-top: 20px;
  padding-bottom: 20px;
  & > section {
    flex-shrink: 0;
    margin-right: 10px;
  }
`;
const SkeletonLoaderWrapper = styled.div `
  display: flex;
  & > svg {
    flex-shrink: 0;
    margin-right: 10px;
  }
`;
const ListingSlider = ({ listings, titleComponent, }) => (_jsxs(Listings, { children: [listings === null || listings === void 0 ? void 0 : listings.map(listing => {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o;
            return (_jsx(ListingCardOld, { id: listing.id, title: (_a = listing.attributes) === null || _a === void 0 ? void 0 : _a.title, campStyles: (_c = (_b = listing.attributes) === null || _b === void 0 ? void 0 : _b.publicData) === null || _c === void 0 ? void 0 : _c.campStyle, rating: (_e = (_d = listing === null || listing === void 0 ? void 0 : listing.attributes) === null || _d === void 0 ? void 0 : _d.metadata) === null || _e === void 0 ? void 0 : _e.rating, providerName: ((_h = (_g = (_f = listing === null || listing === void 0 ? void 0 : listing.author) === null || _f === void 0 ? void 0 : _f.attributes) === null || _g === void 0 ? void 0 : _g.profile) === null || _h === void 0 ? void 0 : _h.displayName) || '', verified: !!((_k = (_j = listing.attributes) === null || _j === void 0 ? void 0 : _j.metadata) === null || _k === void 0 ? void 0 : _k.isVerified), instantBooking: (_m = (_l = listing.attributes) === null || _l === void 0 ? void 0 : _l.publicData) === null || _m === void 0 ? void 0 : _m.instantBookingAvailable, price: (_o = listing.attributes) === null || _o === void 0 ? void 0 : _o.price, images: listing === null || listing === void 0 ? void 0 : listing.images.map((image) => { var _a, _b; return (_b = (_a = image.attributes) === null || _a === void 0 ? void 0 : _a.variants['square-small2x']) === null || _b === void 0 ? void 0 : _b.url; }), shadowVariant: ListingCardShadowVariant.Shadow, titleComponent: titleComponent }, listing.id.uuid));
        }), !listings ||
            (listings && listings.length === 0 && (_jsxs(SkeletonLoaderWrapper, { children: [_jsx(SkeletonLoader, { variant: SkeletonLoaderVariant.ListingCard }), _jsx(SkeletonLoader, { variant: SkeletonLoaderVariant.ListingCard }), _jsx(SkeletonLoader, { variant: SkeletonLoaderVariant.ListingCard }), _jsx(SkeletonLoader, { variant: SkeletonLoaderVariant.ListingCard })] })))] }));
export default ListingSlider;
