import flatMap from 'lodash/flatMap';
import { MarketplaceLocale } from '../config';
import Page from './Page';
import BaseRoutes from './BaseRoutes';
import DeRouteConfiguration from './translations/de.json';
import EnRouteConfiguration from './translations/en.json';
import FrRouteConfiguration from './translations/fr.json';
import ItRouteConfiguration from './translations/it.json';
export const LanguageMap = {
    'en-US': 'en-US',
    'de-DE': 'de-DE',
    'de-CH': 'de-CH',
    'de-AT': 'de-AT',
    'fr-FR': 'fr-FR',
    'fr-CH': 'fr-CH',
    'it-IT': 'it-IT',
    'it-CH': 'it-CH',
};
export const LOCALE_CONFIG = {
    'en-US': { key: 'en-US', short: 'en' },
    'de-DE': { key: 'de-DE', short: 'de' },
    'de-CH': { key: 'de-CH', short: 'de' },
    'de-AT': { key: 'de-AT', short: 'de' },
    'fr-FR': { key: 'fr-FR', short: 'fr' },
    'fr-CH': { key: 'fr-CH', short: 'fr' },
    'it-IT': { key: 'it-IT', short: 'it' },
    'it-CH': { key: 'it-CH', short: 'it' },
};
export const LanguagePrefixMap = {
    de: 'de',
    fr: 'fr',
    it: 'it',
    en: 'en',
};
const LocalePathPrefix = {
    'en-US': '/:language(en|en-US)?',
    'de-DE': '/:language(de|de-DE)?',
    'de-CH': '/:language(de-CH)?',
    'de-AT': '/:language(de-AT)?',
    'fr-FR': '/:language(fr|fr-FR)?',
    'fr-CH': '/:language(fr-CH)?',
    'it-IT': '/:language(it|it-IT)?',
    'it-CH': '/:language(it-CH)?',
};
const RouteConfig = {
    'en-US': EnRouteConfiguration,
    'de-DE': DeRouteConfiguration,
    'de-CH': DeRouteConfiguration,
    'de-AT': DeRouteConfiguration,
    'fr-FR': FrRouteConfiguration,
    'fr-CH': FrRouteConfiguration,
    'it-IT': ItRouteConfiguration,
    'it-CH': ItRouteConfiguration,
};
export const ACCOUNT_SETTINGS_PAGES = [
    Page.ProfileSettings,
    Page.ContactDetails,
    Page.PasswordChange,
];
export var TabState;
(function (TabState) {
    TabState["Disabled"] = "Disabled";
    TabState["Invalid"] = "Invalid";
})(TabState || (TabState = {}));
export const LEGAL_TABS = [
    {
        intlId: 'TermsOfServicePage.tosTabTitle',
        page: Page.TermsOfService,
    },
    {
        intlId: 'GiftCardTermsPage.tosTabTitle',
        page: Page.GiftCardTerms,
    },
    {
        intlId: 'DiscountCodeTermsPage.tosTabTitle',
        page: Page.DiscountCodeTerms,
    },
    {
        intlId: 'TermsOfServicePage.conditionsOfParticipationTitle',
        page: Page.ConditionsOfParticipationPage,
    },
    {
        intlId: 'TermsOfServicePage.legalNoticeTabTitle',
        page: Page.LegalNotice,
    },
    {
        intlId: 'TermsOfServicePage.privacyTabTitle',
        page: Page.PrivacyPolicy,
    },
    {
        intlId: 'TermsOfServicePage.nomadyPactTabTitle',
        page: Page.NomadyPact,
    },
    {
        intlId: 'TermsOfServicePage.feesTabTitle',
        page: Page.Fees,
    },
];
export const ACCOUNT_SETTINGS_TABS = [
    {
        intlId: 'LayoutWrapperAccountSettingsSideNav.profileTabTitle',
        page: Page.ProfileSettings,
    },
    {
        intlId: 'LayoutWrapperAccountSettingsSideNav.contactDetailsTabTitle',
        page: Page.ContactDetails,
    },
    {
        intlId: 'LayoutWrapperAccountSettingsSideNav.passwordTabTitle',
        page: Page.PasswordChange,
    },
    {
        intlId: 'LayoutWrapperAccountSettingsSideNav.languageSettingsTabTitle',
        page: Page.LanguageSettings,
    },
    {
        intlId: 'LayoutWrapperAccountSettingsSideNav.touristTaxReports',
        page: Page.AccountReports,
    },
];
const BaseRouteConfiguration = [
    {
        id: BaseRoutes.Landing,
        name: Page.Landing,
    },
    {
        id: BaseRoutes.AboutUs,
        name: Page.About,
    },
    {
        id: BaseRoutes.Media,
        name: Page.Media,
    },
    {
        id: BaseRoutes['Partner.Tcs'],
        name: Page.PartnerTcs,
    },
    {
        id: BaseRoutes.Partners,
        name: Page.Partner,
    },
    {
        id: BaseRoutes.Contact,
        name: Page.Contact,
    },
    {
        id: BaseRoutes.Jobs,
        name: Page.Jobs,
    },
    {
        id: BaseRoutes.NomadyPact,
        name: Page.NomadyPactMain,
    },
    {
        id: BaseRoutes.Camper,
        name: Page.Camper,
    },
    {
        id: BaseRoutes.BuyGiftCard,
        name: Page.BuyGiftCard,
    },
    {
        id: BaseRoutes.BuyGiftCardBusiness,
        name: Page.BuyGiftCardBusiness,
    },
    {
        id: BaseRoutes.GiftCardOrderConfirmation,
        name: Page.GiftCardOrderConfirmation,
    },
    {
        id: BaseRoutes.Checkout,
        name: Page.Checkout,
        auth: true,
        authPage: Page.Login,
    },
    {
        id: BaseRoutes.Vision,
        name: Page.Vision,
    },
    {
        id: BaseRoutes.Host,
        name: Page.Host,
    },
    {
        id: BaseRoutes.Scout,
        name: Page.Scout,
    },
    {
        id: BaseRoutes.Search,
        name: Page.Search,
    },
    {
        id: BaseRoutes['Region.Single'],
        name: Page.Region,
    },
    {
        id: BaseRoutes['Listing.Index'],
        name: Page.ListingBase,
    },
    {
        id: BaseRoutes['Listing.Single'],
        name: Page.Listing,
    },
    {
        id: BaseRoutes['Listing.Variant'],
        name: Page.ListingVariant,
        auth: true,
        authPage: Page.Login,
    },
    {
        id: BaseRoutes['Listing.Create'],
        name: Page.NewListing,
        auth: true,
        authPage: Page.Login,
    },
    {
        id: BaseRoutes['Listing.Edit.Index'],
        name: Page.EditListing,
        auth: true,
        authPage: Page.Login,
    },
    {
        id: BaseRoutes['Listing.Edit.Payout'],
        name: Page.EditListingPayout,
        auth: true,
        authPage: Page.Login,
    },
    {
        id: BaseRoutes['Listing.Canonical'],
        name: Page.ListingCanonical,
        forceCanonical: Page.Listing,
    },
    {
        id: BaseRoutes['Profile.Index'],
        name: Page.ProfileBase,
    },
    {
        id: BaseRoutes['Profile.Single'],
        name: Page.Profile,
    },
    {
        id: BaseRoutes['Profile.Settings'],
        name: Page.ProfileSettings,
        auth: true,
    },
    {
        id: BaseRoutes.ListingFavoritesLists,
        name: Page.ListingFavoritesLists,
        auth: true,
        authPage: Page.Login,
    },
    {
        id: BaseRoutes.ListingFavoritesList,
        name: Page.ListingFavoritesList,
        auth: true,
        authPage: Page.Login,
    },
    {
        id: BaseRoutes.Login,
        name: Page.Login,
    },
    {
        id: BaseRoutes.Signup,
        name: Page.Signup,
    },
    {
        id: BaseRoutes.PasswordRecovery,
        name: Page.PasswordRecovery,
    },
    {
        id: BaseRoutes['Bookings.Sales'],
        name: Page.InboxBase,
        auth: true,
        authPage: Page.Login,
    },
    {
        id: BaseRoutes['Bookings.Calendar'],
        name: Page.InboxCalendar,
        auth: true,
        authPage: Page.Login,
    },
    {
        id: BaseRoutes['Bookings.Orders'],
        name: Page.InboxOrders,
        auth: true,
        authPage: Page.Login,
    },
    {
        id: BaseRoutes['Account.Reports'],
        name: Page.AccountReports,
        auth: true,
        authPage: Page.Login,
    },
    {
        id: BaseRoutes['Bookings.Index'],
        name: Page.Inbox,
        auth: true,
        authPage: Page.Login,
    },
    {
        id: BaseRoutes['Bookings.Order.Index'],
        name: Page.Order,
        auth: true,
        authPage: Page.Login,
    },
    {
        id: BaseRoutes['Bookings.Order.Details'],
        name: Page.OrderDetails,
        auth: true,
        authPage: Page.Login,
    },
    {
        id: BaseRoutes['Bookings.Sale.Index'],
        name: Page.Sale,
        auth: true,
        authPage: Page.Login,
    },
    {
        id: BaseRoutes['Bookings.Sale.Details'],
        name: Page.SaleDetails,
        auth: true,
        authPage: Page.Login,
    },
    {
        id: BaseRoutes.Listings,
        name: Page.ManageListings,
        auth: true,
        authPage: Page.Login,
    },
    {
        id: BaseRoutes['Account.Index'],
        name: Page.AccountSettings,
        auth: true,
        authPage: Page.Login,
    },
    {
        id: BaseRoutes['Account.ContactDetails'],
        name: Page.ContactDetails,
        auth: true,
        authPage: Page.Login,
    },
    {
        id: BaseRoutes['Account.PasswordChange'],
        name: Page.PasswordChange,
        auth: true,
        authPage: Page.Login,
    },
    {
        id: BaseRoutes['Account.Language'],
        name: Page.LanguageSettings,
        auth: true,
        authPage: Page.Login,
    },
    {
        id: BaseRoutes['Legal.TermsOfService'],
        name: Page.TermsOfService,
    },
    {
        id: BaseRoutes['Legal.GiftCardTerms'],
        name: Page.GiftCardTerms,
    },
    {
        id: BaseRoutes['Legal.DiscountCodeTerms'],
        name: Page.DiscountCodeTerms,
    },
    {
        id: BaseRoutes['Legal.PrivacyPolicy'],
        name: Page.PrivacyPolicy,
    },
    {
        id: BaseRoutes['Legal.NomadyPact'],
        name: Page.NomadyPact,
    },
    {
        id: BaseRoutes['Legal.Fees'],
        name: Page.Fees,
    },
    {
        id: BaseRoutes['Legal.ConditionsOfParticipation'],
        name: Page.ConditionsOfParticipationPage,
    },
    {
        id: BaseRoutes['Legal.LegalNotice'],
        name: Page.LegalNotice,
    },
    {
        id: BaseRoutes['Error.NotFound'],
        name: Page.NotFound,
    },
    {
        id: BaseRoutes.PasswordReset,
        name: Page.PasswordReset,
        withAndWithoutLocalePrefix: true,
    },
    {
        id: BaseRoutes.VerifyEmail,
        name: Page.EmailVerification,
        auth: true,
        authPage: Page.Login,
        withAndWithoutLocalePrefix: true,
    },
    {
        id: BaseRoutes.RentOfficeSpace,
        name: Page.RentOfficeSpace,
    },
    {
        id: BaseRoutes.MyCabin,
        name: Page.MyCabin,
    },
    {
        id: BaseRoutes.MyCabinCamper,
        name: Page.MyCabinCamper,
    },
    {
        id: BaseRoutes.MyCabinHosts,
        name: Page.MyCabinHosts,
    },
    {
        id: BaseRoutes.Fusion,
        name: Page.Fusion,
    },
    {
        id: BaseRoutes.HostFusion,
        name: Page.HostFusion,
    },
    {
        id: BaseRoutes['Inspiration.Single'],
        name: Page.Inspiration,
    },
    {
        id: BaseRoutes.PlateRedirect,
        name: Page.PlateRedirect,
    },
    {
        id: BaseRoutes.Regions,
        name: Page.Regions,
    },
    {
        id: BaseRoutes.Inspirations,
        name: Page.Inspirations,
    },
];
const routeConfiguration = (() => {
    const localizedRoutes = BaseRouteConfiguration.map(element => {
        const routes = [];
        const pathHasLocalePrefix = !element.auth;
        Object.values(MarketplaceLocale).forEach(locale => {
            const pathWithLocalePrefix = `${LocalePathPrefix[locale]}${RouteConfig[locale][element.id]}`;
            const pathWithoutLocalePrefix = `${RouteConfig[locale][element.id]}`;
            if (!element.withAndWithoutLocalePrefix) {
                const path = pathHasLocalePrefix
                    ? pathWithLocalePrefix
                    : pathWithoutLocalePrefix;
                routes.push(Object.assign(Object.assign({}, element), { language: locale, path }));
            }
            else {
                routes.push(Object.assign(Object.assign({}, element), { language: locale, path: pathWithoutLocalePrefix }));
                routes.push(Object.assign(Object.assign({}, element), { language: locale, path: pathWithLocalePrefix }));
            }
        });
        return routes;
    });
    return flatMap(localizedRoutes);
})();
export default routeConfiguration;
