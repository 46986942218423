import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import { Markdown } from 'react-showdown';
import { Carousel } from 'react-responsive-carousel';
import styled from 'styled-components/macro';
import { ColorVariant } from '../../theme';
import Text, { TextVariant } from '../Text/Text';
const Wrapper = styled.section `
  width: 100%;
  display: flex;
  flex-direction: column;

  @media ${props => props.theme.device.tablet} {
    width: inherit;
  }
`;
const Slide = styled.div `
  margin-bottom: 10px;
  width: 290px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  @media ${props => props.theme.device.tablet} {
    margin-right: 30px;
    margin-bottom: 20px;
    width: 375px;
  }
`;
const DesktopWrap = styled.div `
  display: none;

  @media ${props => props.theme.device.tablet} {
    display: flex;
    flex-direction: row;
  }
`;
const MobileWrap = styled.div `
  display: flex;
  flex-direction: row;
  justify-content: center;

  .carousel-root {
    width: 290px;
  }

  .control-dots {
    display: flex;
    justify-content: center;
  }

  @media ${props => props.theme.device.tablet} {
    display: none;
  }
`;
const Illustration = styled.img `
  width: 100%;
  padding: 15px;
`;
const Content = styled.div `
  margin-bottom: 20px;
  @media ${props => props.theme.device.tablet} {
    margin-bottom: initial;
  }
`;
const Title = styled(Text) `
  margin-bottom: 10px;
`;
const IndicatorDot = styled.div `
  width: 6px;
  height: 6px;
  border-radius: 3px;
  margin-right: 6px;
  background: ${props => props.isSelected ? props.theme.color.stone : props.theme.color.fog};
`;
const ImageFlagGrid = ({ elements = [], }) => {
    const [selectedSlide, setSelectedSlide] = useState(0);
    const customDotIndicator = (clickHandler, isSelected, index, label) => {
        return (_jsx("div", Object.assign({ role: "button", "aria-label": `${label} ${index + 1}` }, { children: _jsx(IndicatorDot, { isSelected: isSelected }) }), index));
    };
    const slides = elements.map((element, index) => {
        var _a, _b, _c;
        return (_jsxs(Slide, { children: [_jsx(Illustration, { src: (_c = (_b = (_a = element.image) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.attributes) === null || _c === void 0 ? void 0 : _c.url, loading: "lazy" }), _jsxs(Content, { children: [_jsx(Title, Object.assign({ variant: TextVariant.H6, as: "h1" }, { children: element.title })), _jsx(Text, Object.assign({ colorVariant: ColorVariant.Cave, as: "p" }, { children: _jsx(Markdown, { markdown: element.text }) }))] })] }, index));
    });
    return (_jsxs(Wrapper, { children: [_jsx(DesktopWrap, { children: slides }), _jsx(MobileWrap, { children: _jsx(Carousel, Object.assign({ showThumbs: false, infiniteLoop: true, autoPlay: false, autoFocus: false, interval: 9999999, showArrows: false, showStatus: false, selectedItem: selectedSlide, renderIndicator: customDotIndicator, onClickItem: index => {
                        if (index + 1 < slides.length) {
                            setSelectedSlide(index + 1);
                        }
                        else {
                            setSelectedSlide(0);
                        }
                    } }, { children: slides })) })] }));
};
export default ImageFlagGrid;
