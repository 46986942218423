import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components/macro';
import { DEFAULT_ADULT_QUANTITY, DEFAULT_SEAT_QUANTITY, } from '@nomady/shared/config';
import { setGuestsChecked, setBookingData } from '../ListingPageSlice';
import Button, { ButtonSizeVariant, ButtonOutlineVariant, ButtonColorVariant, } from '../../../styled-components/Button/Button';
import { defaultBookingData } from '../../CheckoutPage/utils/BookingData';
import NumberOfGuestsSelection from '../../../styled-components/NumberOfGuestsSelection/NumberOfGuestsSelection';
const ContentWrap = styled.div `
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;
`;
const ButtonArea = styled.div `
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: ${props => props.showResetButton ? 'space-between' : 'flex-end'};
`;
const MAX_DOGS = 12;
const BookingGuestsSelection = ({ onClose, availableSeats, showSeatSelection = false, }) => {
    var _a;
    const intl = useIntl();
    const dispatch = useDispatch();
    const { bookingData, listing } = useSelector((state) => state.ListingPage);
    const { adultQuantity, seatQuantity, teenQuantity, childQuantity, infantQuantity, dogQuantity, } = bookingData;
    useEffect(() => {
        return () => {
            dispatch(setGuestsChecked(true));
        };
    }, []);
    const { bookablePersonsPerSeat = 0, dogsAllowed = false } = ((_a = listing === null || listing === void 0 ? void 0 : listing.attributes) === null || _a === void 0 ? void 0 : _a.publicData) || {};
    const freeBookablePersons = bookablePersonsPerSeat * seatQuantity -
        adultQuantity -
        teenQuantity -
        childQuantity;
    const minimumSeats = Math.ceil((adultQuantity + teenQuantity + childQuantity) / bookablePersonsPerSeat);
    const onClickSaveGuests = () => {
        dispatch(setGuestsChecked(true));
        if (onClose)
            onClose();
    };
    const onClickResetGuests = () => {
        dispatch(setBookingData(defaultBookingData));
        dispatch(setGuestsChecked(false));
    };
    const defaultMin = 0;
    const minAdult = 1;
    const showResetButton = seatQuantity !== DEFAULT_SEAT_QUANTITY ||
        adultQuantity !== DEFAULT_ADULT_QUANTITY ||
        !!teenQuantity ||
        !!childQuantity ||
        !!infantQuantity ||
        !!dogQuantity;
    return (_jsxs(ContentWrap, { children: [_jsx(NumberOfGuestsSelection, { seats: showSeatSelection ? seatQuantity || DEFAULT_SEAT_QUANTITY : undefined, adults: adultQuantity, teens: teenQuantity || 0, children_: childQuantity || 0, infants: infantQuantity || 0, dogs: dogsAllowed ? dogQuantity || 0 : undefined, onValueChangeSeats: seatQuantity => dispatch(setBookingData({ seatQuantity })), onValueChangeAdults: adultQuantity => dispatch(setBookingData({ adultQuantity })), onValueChangeTeens: teenQuantity => dispatch(setBookingData({ teenQuantity })), onValueChangeChildren: childQuantity => dispatch(setBookingData({ childQuantity })), onValueChangeInfants: infantQuantity => dispatch(setBookingData({ infantQuantity })), onValueChangeDogs: dogQuantity => dispatch(setBookingData({ dogQuantity })), minSeats: minimumSeats, maxSeats: availableSeats, minAdults: minAdult, maxAdults: adultQuantity + freeBookablePersons, minTeens: defaultMin, maxTeens: teenQuantity + freeBookablePersons, minChildren: defaultMin, maxChildren: childQuantity + freeBookablePersons, minInfants: defaultMin, maxInfants: infantQuantity + freeBookablePersons, minDogs: defaultMin, maxDogs: MAX_DOGS }), _jsxs(ButtonArea, Object.assign({ showResetButton: showResetButton }, { children: [showResetButton && (_jsx("div", { children: _jsx(Button, { onClick: onClickResetGuests, label: intl.formatMessage({ id: 'ListingPage.reset' }), sizeVariant: ButtonSizeVariant.Small, outlineVariant: ButtonOutlineVariant.Outlined }) })), _jsx("div", { children: _jsx(Button, { dataTestId: "BookingGuestsSelection_SaveButton", onClick: onClickSaveGuests, label: intl.formatMessage({ id: 'ListingPage.saveGuests' }), sizeVariant: ButtonSizeVariant.Small, colorVariant: ButtonColorVariant.Forest }) })] }))] }));
};
export default BookingGuestsSelection;
