import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import styled from 'styled-components/macro';
import Button, { ButtonColorVariant, ButtonOutlineVariant, } from '../../Button/Button';
const Foot = styled.div `
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-top: 30px;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 40px;
`;
const ButtonLeftWrapper = styled.div `
  min-width: 0;
  justify-self: flex-start;
  margin-right: 5px;
`;
const ButtonRightWrapper = styled.div `
  min-width: 0;
  justify-self: flex-end;
  margin-left: 5px;
`;
const ModalButtons = ({ leftButton, rightButton }) => {
    return (_jsxs(Foot, { children: [leftButton && (_jsx(ButtonLeftWrapper, { children: _jsx(Button, { label: leftButton.label, onClick: leftButton.onClick, outlineVariant: leftButton.isPrimary
                        ? ButtonOutlineVariant.Default
                        : ButtonOutlineVariant.Outlined, colorVariant: leftButton.isPrimary
                        ? ButtonColorVariant.Dark
                        : ButtonColorVariant.Default, responsive: true, inProgress: leftButton.inProgress, disabled: leftButton.disabled }) })), rightButton && (_jsx(ButtonRightWrapper, { children: _jsx(Button, { label: rightButton.label, onClick: rightButton.onClick, outlineVariant: rightButton.isPrimary
                        ? ButtonOutlineVariant.Default
                        : ButtonOutlineVariant.Outlined, colorVariant: rightButton.isPrimary
                        ? ButtonColorVariant.Dark
                        : ButtonColorVariant.Default, responsive: true, inProgress: rightButton.inProgress, disabled: rightButton.disabled }) }))] }));
};
export default ModalButtons;
