import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useState } from 'react';
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import styled, { css } from 'styled-components/macro';
import { useIntl } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { formatCurrencyMajorUnit } from '@nomady/shared/utils/currency';
import { createSlug } from '@nomady/shared/utils/urlHelpers';
import Page from '@nomady/shared/routeConfiguration/Page';
import { pathByRouteName } from '@nomady/shared/utils/routes';
import routeConfiguration from '@nomady/shared/routeConfiguration/routeConfiguration';
import { NamedLink } from '../../components';
import Icon, { IconSizeVariant, IconVariant } from '../Icon/Icon';
import Text, { TextVariant } from '../Text/Text';
import ReviewRating from '../Review/ReviewRating';
import ImageCarousel from '../ImageCarousel/ImageCarousel';
import LikeButton from '../LikeButton/LikeButton';
import AddToFavoritesModal from '../AddToFavoritesModal';
import { ColorVariant } from '../../theme';
import { listingIdsInListingFavoritesListsSelector } from '../../slices/listingFavoritesListsSlice';
import { isAuthenticatedSelector } from '../../ducks/Auth.duck';
import { ModalId, setLastOpenModal } from '../../slices/UISlice';
import campStyleIcons from '../../campStyleIcons';
export var ListingCardVariant;
(function (ListingCardVariant) {
    ListingCardVariant["Default"] = "Default";
    ListingCardVariant["Flag"] = "Flag";
    ListingCardVariant["Summary"] = "Summary";
})(ListingCardVariant || (ListingCardVariant = {}));
export var ListingCardShadowVariant;
(function (ListingCardShadowVariant) {
    ListingCardShadowVariant["Default"] = "Default";
    ListingCardShadowVariant["Shadow"] = "Shadow";
})(ListingCardShadowVariant || (ListingCardShadowVariant = {}));
const Wrapper = styled.section `
  display: flex;
  flex-direction: column;
  width: ${props => (props.fullWidth ? 'calc(100vw - 60px)' : '280px')};
  border-radius: 5px;
  border-color: transparent;
  border-style: solid;
  border-width: 2px;
  background-color: ${props => props.theme.color.pearl};
  overflow: hidden;

  @media ${props => props.theme.device.tablet} {
    ${props => !props.fullWidth &&
    css `
        width: 320px;
      `}
    ${props => (props.variant === ListingCardVariant.Flag || props.responsive) &&
    css `
        flex-direction: row;
        max-width: 100%;
      `}
  }

  @media ${props => props.theme.device.desktop} {
    max-width: 1024px;
  }

  @media ${props => props.theme.device.desktopL} {
    max-width: 100%;
  }

  ${props => props.active &&
    css `
      filter: drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.5));
      border-color: ${props => props.theme.color.forest};
    `}

  ${props => props.shadowVariant === ListingCardShadowVariant.Shadow &&
    css `
      box-shadow: ${props => props.theme.shadow.medium};
    `}
`;
const Content = styled.div `
  padding-top: 16px;
  padding-bottom: 16px;
  @media ${props => props.theme.device.tablet} {
    ${props => (props.variant === ListingCardVariant.Flag || props.responsive) &&
    css `
        padding-top: 10px;
        padding-bottom: 1px;
        width: 50%;
        flex-direction: column;
        align-items: flex-start;
      `}
  }
`;
const Information = styled.div `
  padding-left: 16px;
  padding-right: 16px;

  @media ${props => props.theme.device.tablet} {
    ${props => props.variant === ListingCardVariant.Default &&
    !props.responsive &&
    css `
        border-bottom: 1px solid ${props => props.theme.color.snow};
      `}
  }
`;
const Title = styled.span `
  ${props => props.theme.typeset.H8}
  color: ${props => props.theme.color.cave};
  margin: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
const TopSection = styled.div `
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
`;
const Details = styled.div `
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
  flex-wrap: wrap;

  @media ${props => props.theme.device.tablet} {
    ${props => (props.variant === ListingCardVariant.Flag || props.responsive) &&
    css `
        height: 70px;
        margin: 0;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-end;
      `}
  }
`;
const Capabilities = styled.div `
  display: none;
  ${props => props.hasCapabilities &&
    css `
      display: flex;
      flex-direction: row;
      margin-top: 5px;
      padding: 0;
      gap: 12px;
    `}

  & > div {
    display: flex;
    flex-direction: row;
    @media ${props => props.theme.device.tablet} {
      margin-right: 12px;
    }
  }
`;
const CampStyles = styled.div `
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  & > div {
    margin-right: 8px;
    padding: 0;
  }
`;
const AdditionalInformation = styled.div `
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
  margin: 0;
  margin-top: 5px;
  width: 100%;
`;
const Price = styled(Text) ``;
const ProviderName = styled(Text) `
  margin: 0;
  ${props => props.listingCardVariant === ListingCardVariant.Default &&
    !props.responsive &&
    css `
      max-width: 170px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    `}
`;
const Head = styled.div `
  position: relative;

  @media ${props => props.theme.device.tablet} {
    ${props => (props.variant === ListingCardVariant.Flag || props.responsive) &&
    css `
        width: 50%;
      `}
  }
`;
const FavoriteButton = styled.div `
  position: absolute;
  top: 15px;
  left: 15px;
`;
const InstantBookingIcon = styled(Icon) ``;
const InstantBookingLabel = styled.div `
  position: absolute;
  top: 15px;
  right: 15px;
  height: 24px;
  line-height: 19px;
  min-width: 120px;
  padding: 2px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: ${props => props.theme.color.cave};
  opacity: 0.7;
  border-radius: 16px;

  @media ${props => props.theme.device.tablet} {
    margin-right: 12px;
  }
`;
const CapabilityLabel = styled(Text) `
  margin-left: 5px;
  line-height: inherit;
`;
const RatingAndDistanceWrapper = styled.div `
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  gap: 8px;
`;
/**
 * @deprecated
 */
const ListingCardOld = ({ id, title, campStyles, verified, instantBooking, price, images, providerName, rating, active = false, variant = ListingCardVariant.Default, responsive = false, shadowVariant = ListingCardShadowVariant.Default, hideLikeButton = false, fullWidth = false, titleComponent = 'h5', // should probably be changed to span
distance, }) => {
    const intl = useIntl();
    const liked = useSelector(listingIdsInListingFavoritesListsSelector(id.uuid));
    const isAuthenticated = useSelector(isAuthenticatedSelector);
    const currentURL = useSelector((state) => state.Routing.currentCanonicalUrl);
    const history = useHistory();
    const dispatch = useDispatch();
    const [favoriteBottomSheetOpen, setFavoriteBottomSheetOpen] = useState(false);
    const openFavoritesBottomSheet = () => {
        if (isAuthenticated) {
            setFavoriteBottomSheetOpen(true);
        }
        else {
            const path = pathByRouteName(Page.Signup, routeConfiguration);
            const payload = {
                modalId: ModalId.AddToFavorites,
                metaData: Object.assign(Object.assign({}, id), { backUrl: currentURL || undefined }),
            };
            dispatch(setLastOpenModal(payload));
            history.push(path);
        }
    };
    return (_jsxs(Wrapper, Object.assign({ variant: variant, active: active, responsive: responsive, shadowVariant: shadowVariant, fullWidth: fullWidth }, { children: [_jsxs(Head, Object.assign({ variant: variant, responsive: responsive }, { children: [_jsx(LazyLoadComponent, { children: _jsx(ImageCarousel, { images: images, listingId: id.uuid, listingTitle: title }) }), !hideLikeButton && (_jsxs(FavoriteButton, { children: [_jsx(LikeButton, { liked: liked, onClick: openFavoritesBottomSheet }), _jsx(AddToFavoritesModal, { open: favoriteBottomSheetOpen, onClose: () => setFavoriteBottomSheetOpen(false), listingId: id })] })), variant === ListingCardVariant.Default && instantBooking && (_jsxs(InstantBookingLabel, { children: [_jsx(InstantBookingIcon, { sizeVariant: IconSizeVariant.ExtraExtraSmall, variant: IconVariant.Flash, colorVariant: ColorVariant.Pearl }), _jsx(Text, Object.assign({ colorVariant: ColorVariant.Pearl, variant: TextVariant.H14 }, { children: intl.formatMessage({ id: 'ListingCard.instantBooking' }) }))] }))] })), _jsx(Content, Object.assign({ variant: variant, responsive: responsive }, { children: _jsxs(NamedLink, Object.assign({ name: Page.Listing, params: {
                        id: id.uuid,
                        slug: createSlug(title),
                    } }, { children: [_jsxs(Information, Object.assign({ variant: variant, responsive: responsive }, { children: [_jsxs(TopSection, { children: [_jsx(Title, Object.assign({ as: titleComponent }, { children: title })), variant === ListingCardVariant.Flag && providerName && (_jsx(ProviderName, Object.assign({ listingCardVariant: variant, responsive: responsive, variant: TextVariant.H10B, colorVariant: ColorVariant.Stone }, { children: `${providerName}.` })))] }), _jsxs(Details, Object.assign({ variant: variant, responsive: responsive }, { children: [_jsx(CampStyles, { children: campStyles === null || campStyles === void 0 ? void 0 : campStyles.map(campStyle => (_jsx(Icon, { variant: campStyleIcons[campStyle], sizeVariant: IconSizeVariant.Medium }, `icon-${campStyle}`))) }), _jsxs(Capabilities, Object.assign({ hasCapabilities: verified ||
                                                (variant === ListingCardVariant.Flag && instantBooking) }, { children: [verified && (_jsxs(_Fragment, { children: [_jsx(Icon, { variant: IconVariant.BubbleCheckmark }), _jsx(CapabilityLabel, Object.assign({ variant: TextVariant.H13 }, { children: intl.formatMessage({
                                                                id: 'ListingCard.verified',
                                                            }) }))] })), variant === ListingCardVariant.Flag && instantBooking && (_jsxs(_Fragment, { children: [_jsx(Icon, { variant: IconVariant.BubbleFlash }), _jsx(CapabilityLabel, Object.assign({ variant: TextVariant.H13 }, { children: intl.formatMessage({
                                                                id: 'ListingCard.instantBooking',
                                                            }) }))] }))] }))] }))] })), _jsxs(AdditionalInformation, { children: [variant === ListingCardVariant.Default && providerName && (_jsx(ProviderName, Object.assign({ listingCardVariant: variant, responsive: responsive, variant: TextVariant.H10B, colorVariant: ColorVariant.Stone }, { children: `${providerName}.` }))), _jsxs(RatingAndDistanceWrapper, { children: [_jsx(ReviewRating, { rating: rating }), variant === ListingCardVariant.Flag && distance !== undefined && (_jsx(Text, Object.assign({ variant: TextVariant.H14, colorVariant: ColorVariant.Carrot }, { children: intl.formatMessage({ id: 'ListingCard.distanceInKm' }, { distanceInKm: Math.round(distance) }) })))] }), _jsx(Price, Object.assign({ variant: TextVariant.H10B, colorVariant: ColorVariant.Fir }, { children: formatCurrencyMajorUnit(intl, price) }))] })] })) }))] })));
};
export default ListingCardOld;
