import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import styled from 'styled-components/macro';
import { arrivalTypes, cancellationTypes, } from '@nomady/shared/marketplace-custom-config';
import { CampCategory } from '@nomady/shared/types';
import { IconVariant } from '../../../styled-components/Icon/Icon';
import Text, { TextVariant } from '../../../styled-components/Text/Text';
import { ListingContentModalVariant } from '../ListingPageSlice';
import ListingModal from './ListingModal';
import CampStyleDetailsContent from '../ModalContents/CampStyleDetailsContent';
import CancellationContent from '../ModalContents/CancellationContent';
import ReceptionContent from '../ModalContents/ArrivalContent';
import FaqContent from '../ModalContents/FaqContent';
import RulesContent from '../ModalContents/RulesContent';
import CampSpotsContent from '../ModalContents/CampSpotsContent';
import InstantBookingContent from '../ModalContents/InstantBookingContent';
import VerifiedListingContent from '../ModalContents/VerifiedListingContent';
import { ColorVariant } from '../../../theme';
import DogsContent from '../ModalContents/DogsContent';
const SectionWrapper = styled.div `
  display: flex;
  flex-direction: column;
  background-color: ${props => props.theme.color.pearl};
  margin-top: 20px;
  margin-inline: -15px;
  border-radius: 0;

  @media ${props => props.theme.device.tablet} {
    border-radius: 10px;
    margin-inline: 0;
  }
`;
const TitleWrapper = styled.div `
  display: flex;
  flex-direction: column;
  align-items: left;
  padding: 20px 30px 16px;
  border-radius: 10px 10px 0px 0px;
  background-color: ${props => props.theme.color.pearl};
`;
const OverviewSectionWrapper = styled.ul `
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0px 20px;
  border-radius: 0px 0px 10px 10px;
  background-color: ${props => props.theme.color.pearl};
  margin: 0px;
`;
const PanelWrapper = styled.li `
  border-bottom: 1px solid ${props => props.theme.color.fog};
  padding: 3px 0;
  list-style: none;

  &:last-child {
    border-bottom: 0px none;
  }
`;
const ListingPoliciesSection = () => {
    var _a, _b, _c, _d, _e, _f, _g;
    const state = useSelector((state) => state);
    const intl = useIntl();
    const { listing } = state.ListingPage;
    const isInstantBookingAvailable = listing === null || listing === void 0 ? void 0 : listing.attributes.publicData.instantBookingAvailable;
    const isVerified = (_a = listing === null || listing === void 0 ? void 0 : listing.attributes) === null || _a === void 0 ? void 0 : _a.metadata.isVerified;
    const { seats, dogsAllowed } = ((_b = listing === null || listing === void 0 ? void 0 : listing.attributes) === null || _b === void 0 ? void 0 : _b.publicData) || {};
    const additionalMaxSeatsData = { seats };
    const listingCategory = (_d = (_c = listing === null || listing === void 0 ? void 0 : listing.attributes) === null || _c === void 0 ? void 0 : _c.publicData) === null || _d === void 0 ? void 0 : _d.category;
    const activeMarketPlaceCancellationType = cancellationTypes.find(cancellationType => {
        var _a, _b;
        return cancellationType.key ===
            ((_b = (_a = listing === null || listing === void 0 ? void 0 : listing.attributes) === null || _a === void 0 ? void 0 : _a.publicData) === null || _b === void 0 ? void 0 : _b.cancellationPolicyType);
    });
    const { faq, rules } = ((_f = (_e = listing === null || listing === void 0 ? void 0 : listing.attributes) === null || _e === void 0 ? void 0 : _e.publicData) === null || _f === void 0 ? void 0 : _f.localizedPublicData) || {};
    return (_jsxs(SectionWrapper, { children: [_jsx(TitleWrapper, { children: _jsx(Text, Object.assign({ "data-testid": "ListingPoliciesSection_Title", variant: TextVariant.H30, colorVariant: ColorVariant.Cave, as: "h2" }, { children: intl.formatMessage({ id: 'ListingPage.campPolicies' }) })) }), _jsxs(OverviewSectionWrapper, { children: [_jsx(PanelWrapper, Object.assign({ "data-testid": "ListingPoliciesSection_ListingStyleItem" }, { children: _jsx(ListingModal, Object.assign({ id: ListingContentModalVariant.LISTING_STYLE, iconVariant: IconVariant.Van, titleId: listingCategory === CampCategory.accommodation
                                ? 'ListingPage.accommodationSuitableFor'
                                : 'ListingPage.campStyleIsSuitableFor' }, { children: _jsx(CampStyleDetailsContent, {}) })) })), _jsx(PanelWrapper, { children: _jsx(ListingModal, Object.assign({ id: ListingContentModalVariant.CANCELLATION, iconVariant: IconVariant[activeMarketPlaceCancellationType === null || activeMarketPlaceCancellationType === void 0 ? void 0 : activeMarketPlaceCancellationType.iconName], titleId: "ListingPage.cancellation", subtitle: intl.formatMessage({
                                id: activeMarketPlaceCancellationType === null || activeMarketPlaceCancellationType === void 0 ? void 0 : activeMarketPlaceCancellationType.label,
                            }) }, { children: _jsx(CancellationContent, {}) })) }), dogsAllowed && (_jsx(PanelWrapper, { children: _jsx(ListingModal, Object.assign({ id: ListingContentModalVariant.DOGS, iconVariant: IconVariant.Dog_NEW, titleId: "ListingPage.dogsAllowed" }, { children: _jsx(DogsContent, {}) })) })), _jsx(PanelWrapper, { children: _jsx(ListingModal, Object.assign({ id: ListingContentModalVariant.ARRIVAL, iconVariant: IconVariant.Hand, titleId: "ListingPage.reception", subtitle: intl.formatMessage({
                                id: (_g = arrivalTypes.find(arrivalType => {
                                    var _a, _b;
                                    return arrivalType.key ===
                                        ((_b = (_a = listing === null || listing === void 0 ? void 0 : listing.attributes) === null || _a === void 0 ? void 0 : _a.publicData) === null || _b === void 0 ? void 0 : _b.arrivalType);
                                })) === null || _g === void 0 ? void 0 : _g.label,
                            }) }, { children: _jsx(ReceptionContent, {}) })) }), faq && faq.length > 0 && (_jsx(PanelWrapper, { children: _jsx(ListingModal, Object.assign({ id: ListingContentModalVariant.FAQ, iconVariant: IconVariant.Lightbulb, titleId: "ListingPage.faq" }, { children: _jsx(FaqContent, {}) })) })), rules && rules.length > 0 && (_jsx(PanelWrapper, { children: _jsx(ListingModal, Object.assign({ id: ListingContentModalVariant.RULES, iconVariant: IconVariant.PaperScroll, titleId: "ListingPage.rules" }, { children: _jsx(RulesContent, {}) })) })), seats && seats > 1 && (_jsx(PanelWrapper, Object.assign({ "data-testid": "ListingPoliciesSection_CampSpotsItem" }, { children: _jsx(ListingModal, Object.assign({ id: ListingContentModalVariant.CAMP_SPOTS, iconVariant: IconVariant[`Seat${seats}`], titleId: "ListingPage.campSpots", titleIdAdditionalData: additionalMaxSeatsData }, { children: _jsx(CampSpotsContent, {}) })) }))), !!isInstantBookingAvailable && (_jsx(PanelWrapper, Object.assign({ "data-testid": "ListingPoliciesSection_InstantBookingItem" }, { children: _jsx(ListingModal, Object.assign({ id: ListingContentModalVariant.INSTANT_BOOKING, iconVariant: IconVariant.BubbleFlash, titleId: "ListingPage.instantBooking" }, { children: _jsx(InstantBookingContent, {}) })) }))), !!isVerified && (_jsx(PanelWrapper, { children: _jsx(ListingModal, Object.assign({ id: ListingContentModalVariant.VERIFIED, iconVariant: IconVariant.BubbleCheckmark, titleId: "ListingPage.verifiedByNomady" }, { children: _jsx(VerifiedListingContent, {}) })) }))] })] }));
};
export default ListingPoliciesSection;
