var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { useStripe, useElements } from '@stripe/react-stripe-js';
import { txIsPaymentExpired } from '@nomady/shared/utils/transaction';
import { createSlug } from '@nomady/shared/utils/urlHelpers';
import { confirmPayment, paymentModeSelector } from '../CheckoutPageSlice';
import { currentUserSelector } from '../../../ducks/user.duck';
import PaymentMethodForm from './PaymentMethodForm';
import { pathByRouteName } from '@nomady/shared/utils/routes';
import { routeConfiguration } from '../../..';
import Page from '@nomady/shared/routeConfiguration/Page';
import { getDomainWithProtocol, getMarketplaceDomain, } from '@nomady/shared/utils/localized-domains';
import { PaymentMode } from '@nomady/shared/config';
import { isApp } from '../../../util/deviceContext';
const StripeForm = ({ bookingStart, bookingEnd, listing, lineItemCalculator, giftCards, customerCommissionDiscountCode, existingTransaction, providerStripeAccountId, formRef, onPaymentSuccessful, onExecuteOrder, onCheckoutErrorOccurred, }) => {
    const currentUser = useSelector(currentUserSelector);
    const stripe = useStripe();
    const dispatch = useDispatch();
    const elements = useElements();
    const intl = useIntl();
    const paymentMode = useSelector(paymentModeSelector);
    if (paymentMode === PaymentMode.Legacy) {
        elements === null || elements === void 0 ? void 0 : elements.update({
            // @ts-ignore This property is not typed https://stripe.com/docs/api/payment_intents/object#payment_intent_object-on_behalf_of
            onBehalfOf: providerStripeAccountId,
        });
    }
    const handleSubmit = (event) => __awaiter(void 0, void 0, void 0, function* () {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
        event.preventDefault();
        if (!stripe || elements == null) {
            onCheckoutErrorOccurred({
                message: intl.formatMessage({
                    id: 'CheckoutPage.stripeError.pleaseReloadPage',
                }),
                code: 'stripe-not-loaded',
            });
            return;
        }
        const { error: submitError } = yield elements.submit();
        const formValidationError = !!submitError;
        if (formValidationError) {
            onCheckoutErrorOccurred(submitError);
            return;
        }
        let transaction;
        const existingPaymentIntent = (_b = (_a = existingTransaction === null || existingTransaction === void 0 ? void 0 : existingTransaction.attributes) === null || _a === void 0 ? void 0 : _a.protectedData) === null || _b === void 0 ? void 0 : _b.stripePaymentIntents;
        if (existingPaymentIntent && !txIsPaymentExpired(existingTransaction)) {
            transaction = existingTransaction;
        }
        else {
            transaction = yield onExecuteOrder({
                bookingStart,
                bookingEnd,
                listing,
                lineItemCalculator,
                giftCards,
                customerCommissionDiscountCode,
            });
        }
        const clientSecret = (_f = (_e = (_d = (_c = transaction === null || transaction === void 0 ? void 0 : transaction.attributes) === null || _c === void 0 ? void 0 : _c.protectedData) === null || _d === void 0 ? void 0 : _d.stripePaymentIntents) === null || _e === void 0 ? void 0 : _e.default) === null || _f === void 0 ? void 0 : _f.stripePaymentIntentClientSecret;
        const transactionId = (_g = transaction === null || transaction === void 0 ? void 0 : transaction.id) === null || _g === void 0 ? void 0 : _g.uuid;
        const sharetribeErrorOccured = !(clientSecret && transactionId);
        if (sharetribeErrorOccured) {
            onCheckoutErrorOccurred({
                message: intl.formatMessage({
                    id: 'CheckoutPage.stripeError.contactSupport',
                }),
                code: 'sharetribe-stripe-initiate-order-failed',
            });
            return;
        }
        const domain = getMarketplaceDomain(isApp);
        const domainWithProtocol = getDomainWithProtocol(domain);
        const path = pathByRouteName(Page.Checkout, routeConfiguration, {
            slug: createSlug(listing.attributes.title || ''),
            id: listing.id.uuid,
        });
        const returnUrl = `${domainWithProtocol}${path}`;
        const confirmPaymentParams = {
            elements,
            clientSecret,
            redirect: 'if_required',
            confirmParams: {
                return_url: returnUrl,
                payment_method_data: {
                    billing_details: {
                        name: `${(currentUser === null || currentUser === void 0 ? void 0 : currentUser.attributes.profile.firstName) || ''} ${(currentUser === null || currentUser === void 0 ? void 0 : currentUser.attributes.profile.lastName) || ''}`,
                        email: (currentUser === null || currentUser === void 0 ? void 0 : currentUser.attributes.email) || '',
                        phone: ((_j = (_h = currentUser === null || currentUser === void 0 ? void 0 : currentUser.attributes.profile.privateData) === null || _h === void 0 ? void 0 : _h.phoneNumber) === null || _j === void 0 ? void 0 : _j.number) || '',
                    },
                },
            },
        };
        const { error: confirmPaymentError } = yield stripe.confirmPayment(confirmPaymentParams);
        if (confirmPaymentError) {
            onCheckoutErrorOccurred(confirmPaymentError);
            return;
        }
        const instantBooking = !!((_l = (_k = listing === null || listing === void 0 ? void 0 : listing.attributes) === null || _k === void 0 ? void 0 : _k.publicData) === null || _l === void 0 ? void 0 : _l.instantBookingAvailable);
        yield dispatch(confirmPayment(transaction.id, instantBooking));
        onPaymentSuccessful(transaction);
    });
    return (_jsx("form", Object.assign({ onSubmit: handleSubmit, ref: formRef }, { children: _jsx(PaymentMethodForm, {}) })));
};
export default StripeForm;
