import { jsx as _jsx } from "react/jsx-runtime";
import React, { useState } from 'react';
import styled, { css } from 'styled-components/macro';
import { ReactComponent as MapPinIcon } from './map-pin.svg';
import { ReactComponent as MapPinHoverIcon } from './map-pin-hover.svg';
import { ReactComponent as MapPinActiveIcon } from './map-pin-active.svg';
const Wrapper = styled.div `
  width: 32px;
  height: 38px;
  cursor: pointer;

  ${props => !props.active &&
    props.hovered &&
    css `
      width: 38px;
      height: 44px;
      margin-top: -3px;
      margin-left: -3px;
    `}
`;
const SearchMapPin = ({ active, onClick, }) => {
    const [pinHovered, setPinHovered] = useState(false);
    let mapPin = _jsx(MapPinIcon, {});
    if (active) {
        mapPin = _jsx(MapPinActiveIcon, {});
    }
    else if (pinHovered) {
        mapPin = _jsx(MapPinHoverIcon, {});
    }
    return (_jsx(Wrapper, Object.assign({ hovered: pinHovered, active: active, onMouseEnter: () => !pinHovered && setPinHovered(true), onMouseLeave: () => pinHovered && setPinHovered(false), onClick: (event) => {
            event.stopPropagation();
            if (onClick) {
                onClick();
            }
        } }, { children: mapPin })));
};
export default SearchMapPin;
