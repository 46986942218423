var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createAction, createSlice, } from '@reduxjs/toolkit';
import { CmsSlug } from '@nomady/shared/cmsConfig';
import env from '@nomady/shared/utils/env';
import { storableError } from '../../util/errors';
import { SectionType, } from './ICmsContent';
import { config } from '../..';
import { getCmsEndpoint, getCmsInspirationEndpoint, getCmsRegionEndpoint, } from '../../cmsConfig';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { queryListings, queryReviews } from '../../util/api';
import { getInitialPageTranslationActiveSelector } from '../../slices/UISlice';
import sanitizeString from '../../util/sanitizeString';
import { isApp } from '../../util/deviceContext';
export const BLOG_API_ENDPOINT = env('BLOG_API_ENDPOINT');
export const BLOG_API_ENDPOINT_HOME = `${BLOG_API_ENDPOINT}/home.json`;
const TARGET_LINK_LOCALE_PLACEHOLDER = '{locale}';
export const initialCountryConfig = {
    AT: [],
    DE: [],
    CH: [],
    FR: [],
    IT: [],
};
export var CmsContentPageType;
(function (CmsContentPageType) {
    CmsContentPageType["Default"] = "Default";
    CmsContentPageType["Region"] = "Region";
    CmsContentPageType["Inspiration"] = "Inspiration";
})(CmsContentPageType || (CmsContentPageType = {}));
const initialState = {
    pageType: CmsContentPageType.Default,
    requestCmsContentInProgress: false,
    requestCmsContentError: null,
    cmsContent: {
        schemaTitle: '',
        schemaDescription: '',
        schemaImage: '',
        heroBackgroundImage: undefined,
        heroText: '',
        lead: '',
        sections: [],
        heroBackgroundImageFormats: {},
    },
    blogArticles: [],
    countryConfig: initialCountryConfig,
    featuredListingsRefs: [],
    listingRefs: [],
    queryListingsError: null,
    reviewRefs: [],
    queryReviewsError: null,
};
export const setInitialState = createAction('app/CmsContentPage/setInitialState');
export const CmsContentPageSlice = createSlice({
    name: 'app/CmsContentPage',
    initialState,
    reducers: {
        queryCmsContentRequest: state => {
            state.requestCmsContentInProgress = true;
            state.requestCmsContentError = null;
        },
        queryCmsContentSuccess: (state, action) => {
            state.cmsContent = action.payload;
            state.requestCmsContentInProgress = true;
            state.requestCmsContentError = null;
        },
        queryCmsContentError: (state, action) => {
            state.requestCmsContentError = action.payload;
        },
        queryListingsSuccess: (state, action) => {
            state.listingRefs = [...state.listingRefs, ...action.payload];
            state.queryListingsError = null;
        },
        queryFeaturedListingsSuccess: (state, action) => {
            state.listingRefs = [...state.listingRefs, ...action.payload];
            state.queryListingsError = null;
        },
        queryListingsError: (state, action) => {
            state.queryListingsError = action.payload;
        },
        queryBlogArticlesSuccess: (state, action) => {
            state.blogArticles = action.payload;
        },
        queryCountryConfigSuccess: (state, action) => {
            state.countryConfig = action.payload;
        },
        queryReviewsSuccess: (state, action) => {
            state.reviewRefs = action.payload;
            state.queryReviewsError = null;
        },
        queryReviewsError: (state, action) => {
            state.queryReviewsError = action.payload;
        },
        setPageType: (state, action) => {
            state.pageType = action.payload;
        },
        setDynamicLocalizedRouteNames: (state, action) => {
            state.dynamicLocalizedRouteNames = action.payload;
        },
    },
    extraReducers: builder => {
        builder.addCase(setInitialState, () => {
            return initialState;
        });
    },
});
export const { queryCmsContentRequest, queryCmsContentSuccess, queryCmsContentError, queryListingsSuccess, queryFeaturedListingsSuccess, queryListingsError, queryBlogArticlesSuccess, queryCountryConfigSuccess, queryReviewsSuccess, queryReviewsError, setPageType, setDynamicLocalizedRouteNames, } = CmsContentPageSlice.actions;
export const requestCmsContent = (locale, endpoint) => (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
    var _a, _b, _c, _d;
    try {
        let json;
        if (isApp) {
            try {
                const urlPath = endpoint.split('/').pop();
                if (urlPath !== undefined) {
                    const response = yield fetch(`/static/cmsCache/${sanitizeString(urlPath)}.json`);
                    if (!response.ok) {
                        throw new Error(`HTTP error! status: ${response.status}`);
                    }
                    json = yield response.json();
                }
            }
            catch (error) {
                console.error(`Error fetching data:`, error);
            }
        }
        if (!json) {
            const response = yield fetch(endpoint);
            json = yield response.json();
        }
        const { attributes: page, localizedRouteNames } = ((_a = json === null || json === void 0 ? void 0 : json.data) === null || _a === void 0 ? void 0 : _a[0]) || {};
        if (localizedRouteNames) {
            dispatch(setDynamicLocalizedRouteNames(localizedRouteNames));
        }
        const { title, mainTitle, leadText, leadVariant, heroText, description, sections: pageSections, heroBackgroundImage, seo, } = page;
        const image = ((_d = (_c = (_b = page.image) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.attributes) === null || _d === void 0 ? void 0 : _d.url) || '';
        const heroBackgroundImageFormats = heroBackgroundImage === null || heroBackgroundImage === void 0 ? void 0 : heroBackgroundImage.formats;
        const schemaTitle = `${title || config.siteTitle} ${config.custom.seoSuffix}`;
        const loadFeaturedListings = pageSections.find((section) => 
        // eslint-disable-next-line no-underscore-dangle
        (section.__component === SectionType.ListingSliderSection ||
            section.__component === SectionType.ListingGridSection) &&
            section.featured);
        if (loadFeaturedListings) {
            dispatch(requestFeaturedListings(locale));
        }
        const listingIds = pageSections
            .filter((section) => 
        // eslint-disable-next-line no-underscore-dangle
        (section.__component === SectionType.ListingSliderSection ||
            section.__component === SectionType.ListingGridSection) &&
            !section.featured)
            .flatMap((section) => section.listingIds.map(listing => listing.listingId));
        if (listingIds && listingIds.length > 0) {
            dispatch(requestMarketplaceListings(locale, { ids: listingIds }));
        }
        const sections = pageSections.map((section) => {
            // eslint-disable-next-line no-underscore-dangle
            if (section.__component === SectionType.InspirationsSection) {
                return Object.assign(Object.assign({}, section), { inspirationExcerpts: section.inspirationExcerpts.map(inspirationExcerpt => {
                        var _a;
                        return (Object.assign(Object.assign({}, inspirationExcerpt), { targetLink: (_a = inspirationExcerpt.targetLink) === null || _a === void 0 ? void 0 : _a.replaceAll(TARGET_LINK_LOCALE_PLACEHOLDER, locale) }));
                    }) });
            }
            return section;
        });
        const cmsContent = {
            schemaTitle,
            schemaDescription: description,
            schemaImage: image,
            mainTitle,
            heroBackgroundImage,
            heroText,
            lead: leadText,
            leadVariant,
            sections,
            heroBackgroundImageFormats,
            seo,
        };
        dispatch(queryCmsContentSuccess(cmsContent));
        const containsBlogSection = pageSections.find((section) => 
        // eslint-disable-next-line no-underscore-dangle
        section.__component === SectionType.BlogArticlesSection);
        if (containsBlogSection) {
            dispatch(requestBlogArticles());
        }
    }
    catch (error) {
        console.error(error);
    }
});
export const requestCmsRegion = (name, locale) => (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
    var _e, _f, _g, _h, _j;
    dispatch(queryCmsContentRequest());
    const regionEndpoint = getCmsRegionEndpoint(name, locale);
    const pageEndpoint = getCmsEndpoint(CmsSlug.Region, locale);
    try {
        const pagePromise = fetch(pageEndpoint);
        const regionPromise = fetch(regionEndpoint);
        const [pageResponse, regionResponse] = yield Promise.all([
            pagePromise,
            regionPromise,
        ]);
        const regionJson = yield regionResponse.json();
        const { attributes: regionData, localizedRouteNames } = ((_e = regionJson === null || regionJson === void 0 ? void 0 : regionJson.data) === null || _e === void 0 ? void 0 : _e[0]) || {};
        if (localizedRouteNames) {
            dispatch(setDynamicLocalizedRouteNames(localizedRouteNames));
        }
        const { name, pronoun, subTitle, description, heroBackgroundImage, slug, centerCoordinates, titlePrefix, seo, } = regionData;
        const regionSpecificSections = regionData.sections;
        const pageJson = yield pageResponse.json();
        const pageData = (_f = pageJson === null || pageJson === void 0 ? void 0 : pageJson.data) === null || _f === void 0 ? void 0 : _f[0].attributes;
        const { sections } = pageData;
        const image = ((_j = (_h = (_g = regionData.image) === null || _g === void 0 ? void 0 : _g.data) === null || _h === void 0 ? void 0 : _h.attributes) === null || _j === void 0 ? void 0 : _j.url) || '';
        const heroBackgroundImageFormats = heroBackgroundImage === null || heroBackgroundImage === void 0 ? void 0 : heroBackgroundImage.formats;
        const schemaTitle = `${name || config.siteTitle} ${config.custom.seoSuffix}`;
        const REGION_SPECIFIC_SECTIONS_INDEX = 2;
        const nameWithPronoun = `${pronoun} ${name}`;
        const prefix = titlePrefix ? `${titlePrefix} ` : '';
        const heroText = `${prefix}${nameWithPronoun}`;
        const cmsContent = {
            schemaTitle,
            schemaImage: image,
            schemaDescription: description,
            heroText,
            lead: description,
            heroBackgroundImage,
            name,
            subTitle,
            pronoun,
            description,
            sections: [
                ...sections.slice(0, REGION_SPECIFIC_SECTIONS_INDEX),
                ...regionSpecificSections,
                ...sections.slice(REGION_SPECIFIC_SECTIONS_INDEX, sections.length),
            ],
            heroBackgroundImageFormats,
            localizedRouteNames,
            centerCoordinates,
            titlePrefix,
            seo,
            slug,
        };
        dispatch(queryCmsContentSuccess(cmsContent));
        dispatch(requestMarketplaceListings(locale, { meta_regions: slug }));
    }
    catch (error) {
        console.error(error);
        dispatch(queryCmsContentError(error));
    }
});
export const loadRegionData = (params) => (dispatch, getState) => {
    const state = getState();
    const { locale } = state.UI;
    const { name: slug } = params;
    dispatch(loadData(CmsSlug.Region, CmsContentPageType.Region));
    return dispatch(requestCmsRegion(slug, locale));
};
export const loadInspirationData = (params) => (dispatch, getState) => {
    const { name: slug } = params;
    return dispatch(loadData(slug, CmsContentPageType.Inspiration));
};
export const loadPageData = (slug) => (dispatch, getState) => {
    return dispatch(loadData(slug));
};
export const loadData = (slug, pageType = CmsContentPageType.Default) => (dispatch, getState) => {
    const state = getState();
    const { locale } = state.UI;
    const getEndpoint = (pageType, slug, locale) => {
        if (pageType === CmsContentPageType.Region) {
            return getCmsRegionEndpoint(slug, locale);
        }
        if (pageType === CmsContentPageType.Inspiration) {
            return getCmsInspirationEndpoint(slug, locale);
        }
        return getCmsEndpoint(slug, locale);
    };
    const endpoint = getEndpoint(pageType, slug, locale);
    // Clear state so that previously loaded data is not visible
    // in case this page load fails.
    dispatch(setPageType(pageType));
    dispatch(requestCountryConfig(locale));
    return dispatch(requestCmsContent(locale, endpoint));
};
export const requestMarketplaceListings = (locale, 
// eslint-disable-next-line camelcase
params = {}) => (dispatch, _) => __awaiter(void 0, void 0, void 0, function* () {
    const defaultParams = {
        include: [
            'author',
            'images',
            'listing',
            'author.profileImage',
            'reviews',
        ],
        'fields.image': [
            'title',
            'description',
            // Listing page
            'variants.landscape-crop',
            'variants.landscape-crop2x',
            'variants.landscape-crop4x',
            'variants.landscape-crop6x',
            // Social media
            'variants.facebook',
            'variants.twitter',
            // Image carousel
            'variants.scaled-small',
            'variants.scaled-medium',
            'variants.scaled-large',
            'variants.scaled-xlarge',
            // Avatars
            'variants.square-small',
            'variants.square-small2x',
        ],
        perPage: 100,
        sort: '-meta_order,meta_rating,createdAt',
    };
    try {
        const response = yield queryListings(Object.assign(Object.assign(Object.assign({}, defaultParams), params), { locale }));
        dispatch(addMarketplaceEntities(response));
        const respondedListings = response.data.data.map(listing => {
            return {
                id: listing.id,
                type: listing.type,
            };
        });
        dispatch(queryListingsSuccess(respondedListings));
        dispatch(requestMarketplaceReviews(respondedListings));
    }
    catch (e) {
        console.table({ e });
        dispatch(queryListingsError(storableError(e)));
    }
});
export const requestMarketplaceReviews = (listingIds) => (dispatch, getState) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const state = getState();
        const locale = getInitialPageTranslationActiveSelector(state)
            ? { locale: state.UI.locale }
            : {};
        const reviewRefs = [];
        const promises = listingIds
            .filter(listing => !!(listing === null || listing === void 0 ? void 0 : listing.id))
            .map((listing) => __awaiter(void 0, void 0, void 0, function* () {
            var _k;
            const params = Object.assign({ include: ['author', 'listing', 'author.profileImage'], 'fields.image': [
                    // Avatars
                    'variants.square-small',
                    'variants.square-small2x',
                ], limit: 1, listingId: listing.id }, locale);
            const response = yield queryReviews(params);
            const firstReview = (_k = response.data.data) === null || _k === void 0 ? void 0 : _k[0];
            if (firstReview) {
                dispatch(addMarketplaceEntities(response));
                reviewRefs.push({
                    id: firstReview === null || firstReview === void 0 ? void 0 : firstReview.id,
                    type: firstReview.type,
                });
            }
        }));
        yield Promise.all(promises);
        dispatch(queryReviewsSuccess(reviewRefs));
    }
    catch (e) {
        console.error({ e });
    }
});
const requestBlogArticles = () => (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
    const requestConfig = {
        method: 'get',
    };
    try {
        const response = yield fetch(BLOG_API_ENDPOINT_HOME, requestConfig);
        const json = yield response.json();
        const homepageContent = json.data[0];
        const blogArticles = homepageContent.blogItems.map((blogItem) => ({
            image: blogItem.image,
            headline: blogItem.headline,
            title: blogItem.title,
            excerpt: blogItem.headline,
            category: blogItem.category,
            categoryColor: blogItem.category_colour,
            date: blogItem.date,
            writer: blogItem.writer,
            url: blogItem.url,
        }));
        dispatch(queryBlogArticlesSuccess(blogArticles));
    }
    catch (error) {
        console.error(error);
    }
});
export const requestFeaturedListings = (locale) => (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
    const params = {
        meta_featured: true,
        include: ['author', 'images', 'listing', 'author.profileImage'],
        'fields.image': [
            'title',
            'description',
            // Listing page
            'variants.landscape-crop',
            'variants.landscape-crop2x',
            'variants.landscape-crop4x',
            'variants.landscape-crop6x',
            // Social media
            'variants.facebook',
            'variants.twitter',
            // Image carousel
            'variants.scaled-small',
            'variants.scaled-medium',
            'variants.scaled-large',
            'variants.scaled-xlarge',
            // Avatars
            'variants.square-small',
            'variants.square-small2x',
        ],
        perPage: 10,
        sort: '-meta_order',
        locale,
    };
    try {
        const listings = yield queryListings(params);
        const listingRefs = listings.data.data.map(({ id, type }) => ({
            id,
            type,
        }));
        dispatch(addMarketplaceEntities(listings));
        dispatch(queryFeaturedListingsSuccess(listingRefs));
    }
    catch (error) {
        console.error(error);
    }
});
export const requestCountryConfig = (locale) => (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
    const regionEndpoint = getCmsRegionEndpoint('', locale);
    const response = yield fetch(regionEndpoint);
    const regionJson = yield response.json();
    const { countryConfig } = regionJson === null || regionJson === void 0 ? void 0 : regionJson.meta;
    dispatch(queryCountryConfigSuccess(countryConfig));
});
export default CmsContentPageSlice.reducer;
