var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { distanceSortKey } from '@nomady/shared/marketplace-custom-config';
import { config } from '..';
import { userLocation } from '../util/maps';
import { setOrigin, setSort, setLocationBounds, loadResults, setMapSearchEnabled, } from './listingSearchSlice';
import GeolocationSource from '../util/GeolocationSource';
import { getBoundsOfDistance } from '../util/getBoundsOfDistance';
const initialState = {
    browserGeolocationInProgress: false,
    browserGeolocationError: null,
    showRequestBrowserGeolocationModal: false,
    geolocationSource: undefined,
};
export const geolocationSlice = createSlice({
    name: 'app/Location',
    initialState,
    reducers: {
        setShowRequestBrowserGeolocationModal: (state, action) => {
            state.showRequestBrowserGeolocationModal = action.payload;
        },
        browserGeolocationRequest: state => {
            state.browserGeolocationInProgress = true;
            state.browserGeolocationError = null;
        },
        browserGeolocationSuccess: state => {
            state.browserGeolocationInProgress = false;
            state.browserGeolocationError = null;
            state.showRequestBrowserGeolocationModal = false;
        },
        browserGeolocationError: (state, action) => {
            state.browserGeolocationInProgress = false;
            state.browserGeolocationError = action.payload;
        },
        setGeolocationSource: (state, action) => {
            state.geolocationSource = action.payload;
        },
    },
});
export const { setShowRequestBrowserGeolocationModal, browserGeolocationRequest, browserGeolocationSuccess, browserGeolocationError, setGeolocationSource, } = geolocationSlice.actions;
export const requestBrowserGeolocation = () => (dispatch, getState) => __awaiter(void 0, void 0, void 0, function* () {
    dispatch(browserGeolocationRequest());
    dispatch(setMapSearchEnabled(false));
    try {
        const latlng = yield userLocation();
        const bounds = getBoundsOfDistance(latlng, config.maps.search.currentLocationBoundsDistance);
        const state = getState();
        dispatch(setOrigin(latlng));
        dispatch(setSort(distanceSortKey));
        if (state.geolocation.geolocationSource !== GeolocationSource.Sort) {
            dispatch(setLocationBounds(bounds));
        }
        dispatch(browserGeolocationSuccess());
        dispatch(loadResults());
    }
    catch (error) {
        dispatch(browserGeolocationError(error));
    }
});
export default geolocationSlice.reducer;
